<template>
  <div class="setting">
      <el-button type="primary" @click="downloadRegisterFile">下载注册信息</el-button>
      <el-button type="primary" @click="downloadTransferFile">下载迁移信息</el-button>
      <el-button type="primary" @click="$refs.uploadLicence.click()">更新许可证</el-button>
      <input type="file" style="display: none;" :ref="'uploadLicence'" @change="uploadLicence" />
  </div>
</template>

<script>

import {importFile} from "@/utils/ajax";

export default {
  name: 'index',
  data() {
    return {}
  },
  methods: {
    downloadRegisterFile() {
      // window.open(this.$api.downloadRegisterFile, '_self');
      // importFile({
      //   url: this.$api.downloadRegisterFile,
      //   // lang:企业用户账号
      //   name: '注册信息',
      //   type: '.docx',
      //   data: {},
      // })
      const type = '.docx'
      this.$ajax({
        url: this.$api.downloadRegisterFile,
        defineDeal: true,
        notUnpacking: true,
        data: {}
      }).then((result) => {
        console.log(result)
        const { data, headers } = result
        const name = headers['content-disposition'].split('=')[1]
        let newType
        switch (type) {
          case '.xlsx':
            newType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            break
          case '.doc':
            newType = 'application/msword'
            break
          case '.docx':
            newType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            break
          case '.zip':
            newType = 'application/zip'
            break
          default: // do something
        }
        const url = window.URL.createObjectURL(
            new Blob([data], {
              type: newType,
            })
        )
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    downloadTransferFile() {
      // importFile({
      //   url: this.$api.downloadTransferFile,
      //   // lang:企业用户账号
      //   name: '迁移文件',
      //   type: '.docx',
      //   data: {},
      // })

      const type = '.docx'
      this.$ajax({
        url: this.$api.downloadTransferFile,
        defineDeal: true,
        notUnpacking: true,
        data: {}
      }).then((result) => {
        console.log(result)
        const { data, headers } = result
        const name = headers['content-disposition'].split('=')[1]
        let newType
        switch (type) {
          case '.xlsx':
            newType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            break
          case '.doc':
            newType = 'application/msword'
            break
          case '.docx':
            newType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            break
          case '.zip':
            newType = 'application/zip'
            break
          default: // do something
        }
        const url = window.URL.createObjectURL(
            new Blob([data], {
              type: newType,
            })
        )
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    uploadLicence() {
      const file = this.$refs.uploadLicence.files[0]
      const fd = new FormData()
      fd.append('file', file)
      fd.append('fileName', file.name)
      fd.append('fileFrom', 'SERVER_LICENCE')
      this.$ajax({
        url: this.$api.updateLicence,
        data: fd,
      }).then((data) => {
        this.$message('更新成功')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .setting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .el-button {
      width: 300px;
      margin-bottom: 20px;
    }
  }
</style>
