export default {
    title: '用户权限',
    name: '姓名',
    department: '部门',
    account: '账号',
    productKey: '产品密钥',
    webEditProductKey: '编辑管理中心',
    thermalImagingFunction: '热成像功能',
    remoteControlFunction: '远程控制功能',
    sensorAcquisitionFunction: '传感器采集功能',
    endoscopicFunction: '内窥镜功能',
    contactVisibleRange: '联系人可见范围',
    addContact: '添加联系人',
    accountGroup: '所属部门',
    nameOrAccount: '姓名/账号',
    targetAccount: '目标账号',
    notHave: '无',
    end: '截止',
    allow: '允许',
    notAllow: '不允许',
    notSet: '未设置',
    renewal: '续订',
    open: '开通',
    setting: '设置',
    allPermission: '所有人',
    deactivate: '禁用',
    changeContactVisibleRange: '修改联系人可见范围',
    deactivateConfirm: '服务被禁用后，已分配的密钥将无法恢复，是否确认执行？',
    serviceRenewal: '服务续订',
    serviceOpen: '服务开通',
    validYears: '当前服务截止时间',
    taskVisible: '任务可见范围',
    taskDistributeVisible: '任务派发范围',
    fileShareVisible: '文件对外分享范围',
    monitoringVisible: '监控可见范围',
    reportVisible: '报告可见范围',
    dataAnalysisVisible: '数据分析范围',
    dataApiVisible: '数据接口管理范围',

    dataSetVisible: '数据集可见范围',
    algorithmEngineeringVisible: '算法工程可见范围',
    algorithmModelFileExport: '算法模型文件导出',
    algorithmModelPublish: '算法模型文件发布',
    algorithmModelDelete: '算法模型删除',
    aiAppVisible: 'AI应用可见范围',
    aiAppPublish: 'AI应用发布',
    aiAppDelete: 'AI应用删除',
    dataSetFileExport: '数据集文件导出',
    dataSetDelete: '数据集文件删除',
    realTimeMonitoringVisible: '实时监控可见范围',
    self: '仅自己',
}
