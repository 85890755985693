// 识别算法管理
export default {
    title:'算法列表',
    type: '算法来源',
    name: '算法名称',
    algorithmPlatform: '所属算法平台',
    aiStationId: '算法三方ID',
    aiStationIdName: '标识名称',
    externalApiName: '调用API的名称',
    externalAiName: '算法三方名称',
    selectExternalApi: '选择调用的API',
    selectPlatform: '选择算法平台',
    selectPlatformAiStationId: '选择平台标识',
    recognitionType: '识别类型',
    category: '分类',
    read: '读数',
    confidence: '置信度',
    content: '识别内容',
    recognitionTypeTip: '请添加识别类型',
    recognitionTypeInfoTip: '识别类型信息不完整',
    labelName: '标签名称',
    labelType: '标签数据类型',
}
