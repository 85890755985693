// 业务枚举
export default {
  // enableVale-状态
  NORMAL: '正常',
  DISABLE: '禁用',
  DELETE: '删除',
  // yn-是非
  YES: '是',
  NO: '否',
  // ContextFrom-会话来源
  REQUEST: '外部请求',
  SCHEDULE: '定时任务',
  WEB_SOCKET: 'webSocket',
  // level-等级
  ONE: '一级',
  TWO: '二级',
  THREE: '三级',
  FOUR: '四级',
  FIVE: '五级',
  SIX: '六级',
  SEVEN: '七级',
  EIGHT: '八级',
  NINE: '九级',
  TEN: '十级',
  // DictTypeEnum-字典类型
  ADDRESS: '地址',
  COMPANY_INDUSTRY: '行业',
  VERSION_CATEGORY: '版本分类',
  WORK_FLOW_STEP_GROUP: '工作流模板分组类型',
  CLIENT_LOG_TYPE: '终端日志类型',
  DOCUMENT_TYPE: '文档管理',
  // permissionType-功能类型
  ROOT: '根目录',
  ONE_LIST: '一级功能',
  TWO_LIST: '二级功能',
  BUTTON: '按钮功能',
  // SubjectTypeEnum-主体类型
  PC_MANAGE: '系统管理',
  // RoleTypeEnum-角色类型
  COMPANY_MANAGER: '企业管理员',
  SUB_MANAGER: '子管理员',
  // AuthType-鉴权类型
  ANONYMOUS: '匿名访问',
  LOGIN: '登录之后访问',
  AUTHORIZED: '授权后访问',
  // FileFrom-文件来源
  WEB_ADMIN: '管理后台',
  // LanguageEnum-语言类型
  en: '英文',
  zhCN: '中文',
  // invoiceType-发票类型
  GENERAL: '普票',
  VAT: '增值税发票',
  // ServiceResourceAssignStatusEnum-授权状态
  ASSIGNED: '已授权',
  DEDUCTED: '已扣除',
  ROLLBACK: '已撤销',
  ASSIGNED_1: '已分配',
  ROLLBACK_1: '已撤销',
  ASSIGNED_2: '已激活',
  ROLLBACK_2: '未激活',
  // ServiceResourceTypeEnum-资源类型
  ACCOUNT_NUMBER: '账号数量(个)',
  ACCOUNT_SUBSCRIPTIONS_NUMBER: '账号订阅数量(个)',
  EQUIPMENT_NUMBER: '设备码数量(个)',
  EQUIPMENT_COMPONENTS_NUMBER: '设备备件数量(个)',
  AR_DIGITAL_DISPLAY_NUMBER: 'AR数显数量(个)',
  VIDEO_CALL_DURATION: '通话时长(分钟)',
  CLOUD_RECORDING_STORAGE_SPACE: '云录制空间(G)',
  REMAIN_CLOUD_RECORDING_STORAGE_SPACE: '剩余云录制空间(G)',
  OSS_STORAGE_SPACE: '云存储空间(G)',
  REMAIN_OSS_STORAGE_SPACE: '剩余云存储空间(G)',
  PRODUCT_KEY_NUMBER:'产品密钥数量(个)',
  REMAIN_PRODUCT_KEY_NUMBER:'剩余产品密钥数量(个)',
  THERMAL_IMAGERY_FUNCTION_KEY_NUMBER:'热成像功能密钥数量(个)',
  REMAIN_THERMAL_IMAGERY_FUNCTION_KEY_NUMBER:'剩余热成像功能密钥数量(个)',
  TEAM_VIEWER_FUNCTION_KEY_NUMBER:'远程控制功能密钥数量(个)',
  REMAIN_TEAM_VIEWER_FUNCTION_KEY_NUMBER:'剩余远程控制功能密钥数量(个)',
  SENSOR_COLLECT_FUNCTION_KEY_NUMBER:'传感器采集功能密钥数量(个)',
  REMAIN_SENSOR_COLLECT_FUNCTION_KEY_NUMBER:'剩余传感器采集成像功能密钥数量(个)',
  ENDOSCOPE_FUNCTION_KEY_NUMBER:'内窥镜功能密钥数量(个)',
  REMAIN_ENDOSCOPE_FUNCTION_KEY_NUMBER:'剩余内窥镜功能密钥数量(个)',
  WEB_EDIT_PRODUCT_KEY_NUMBER: '功能密钥-WEB编辑端数量(个)',
  REMAIN_WEB_EDIT_PRODUCT_KEY_NUMBER: '剩余功能密钥-WEB编辑端数量(个)',
  // ServiceResourceFeeModeEnum-资源计费模式
  METERING: '按量付费',
  ACCOUNT_SUBSCRIPTIONS: '账户订阅',
  // ARTypeEnum -类型池枚举
  MAC: 'MAC地址',
  OAID: 'OAID地址',
  // ScopeEnum -范围枚举
  GLOBAL: '全局企业可见',
  scope_COMPANY: '当前企业可见',
  CHANNEL_LINE: '渠道线企业可见',
  // UserAccountTypeEnum -终端类型
  SEATS: '座席端',
  AR: 'AR端',
  MOBILE: '移动端',
  // UserAccountTypeEnum -用户账号类型枚举
  PC_USER: 'PC端',
  WEB: 'WEB端',
  ANDROID: '手机端',
  ANDROID_AR: 'AR端',
  WEB_ADMIN_USER: '管理端',
  UNKNOWN: '未知',
  // UserAccountSubscribeTypeEnum -用户账号订阅方式枚举
  AUTO: '自动',
  MANUAL: '手动',
  // messageType-消息类型
  SYSTEM_NOTICE: '系统通知',
  // messageTarget-消息群体
  ALL: '全局',
  CHANNEL: '渠道线',
  USER_ACCOUNT: '指定账号',
  // osType-终端类型
  APK: '安卓APK',
  PC: 'PC应用',
  // versionType-版本类型
  MASTER: '主版本',
  HISTORY: '历史',
  BETA: 'beta',
  // 网关操作日志类型
  QUERY: '查询',
  RESET: '重置密码',
  // osAccountType-终端权限
  DeviceWEB: 'WEB端',
  DeviceANDROID: 'ANDROID端',
  DevicePC: 'PC端',
  // Http前缀
  Http:'http',
  Https:'https',
  // 账号类型
  SYSTEM_ADMINISTRATOR:'系统超管',
  MANAGER: '管理员',
  USER_ACCOUNT_NEW:'用户账户',
  // SecretKeyType 密钥类型
  PRODUCT_KEY: '产品密钥',
  WEB_EDIT_PRODUCT_KEY: '功能密钥-WEB编辑端',
  FUNCTION_KEY: '功能密钥',
  THERMAL_IMAGERY_FUNCTION_KEY:'热成像',
  TEAM_VIEWER_FUNCTION_KEY: '远程控制',
  SENSOR_COLLECT_FUNCTION_KEY: '传感器采集',
  ENDOSCOPE_FUNCTION_KEY: '内窥镜',
  REMOTE_GUIDANCE:'远程指导',
  TMVA: '远程专家指导套件',
  CORE: '账号管理',
  TMVA_IDC: '传感及数据接口管理',
  XR_WORK: 'SOP执行与追溯套件',
  SPOT_INSPECTION: '点检验收',
  ASSEMBLY_INSTRUCTION: '装配质检',
  PRACTICAL_TRAINING_EDUCATION: '实操培训',
  SAFETY_MANAGEMENT: '安全检查',
  NORMAL1: '普通',
  AI_STATION: 'AI算法能力开放套件'
}
