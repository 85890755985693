var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"loginPage"},[_c('div',{staticClass:"loginBox"},[_c('div',{staticClass:"leftImg"},[_c('div',{staticClass:"iconImg leftShowImg"}),(! _vm.isPermanent)?_c('div',{staticClass:"expired"},[_vm._v(_vm._s(_vm.$t('base.licenceTip'))+":"+_vm._s(_vm.expiredTime)),(_vm.showUpdateLicence)?_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.$refs.uploadLicence.click()}}},[_vm._v(_vm._s(_vm.$t('base.updateLicence')))]):_vm._e()]):_vm._e(),_c('input',{ref:'uploadLicence',staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.uploadLicence}})]),_c('div',{staticClass:"rightContent"},[_c('div',{staticClass:"language"},[_c('el-radio-group',{attrs:{"size":"mini","fill":"#525F6B"},on:{"change":_vm.changeLanguage},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}},[_c('el-radio-button',{attrs:{"label":"zhCN"}},[_vm._v(_vm._s(_vm.$t('base.zhCN')))]),_c('el-radio-button',{attrs:{"label":"en","disabled":""}},[_vm._v(_vm._s(_vm.$t('base.en')))]),_c('el-radio-button',{attrs:{"label":"ja","disabled":""}},[_vm._v(_vm._s(_vm.$t('base.ja')))])],1)],1),_c('div',{staticClass:"innerContent"},[_c('div',{staticClass:"iconImg logoIcon",style:({
            backgroundImage:
              'url(' +
              (_vm.customCompanyInfo
                ? _vm.customCompanyInfo.logo.fileHost +
                  '/' +
                  _vm.customCompanyInfo.logo.objectName
                : require('./img/logo.png')) +
              ')',
          })}),_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"size":"medium"}},[_c('el-form-item',{staticClass:"inputBottom",attrs:{"prop":"loginName","rules":_vm.$validate({
                noNull: true,
                label: _vm.$t('base.loginName'),
                tips: _vm.$t('base.pleaseEnter'),
              })}},[_c('el-input',{attrs:{"placeholder":_vm.$t('base.loginNameHolder'),"clearable":""},model:{value:(_vm.formData.loginName),callback:function ($$v) {_vm.$set(_vm.formData, "loginName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.loginName"}},[_c('i',{staticClass:"iconfont iconMargin",attrs:{"slot":"prefix"},slot:"prefix"},[_c('img',{attrs:{"src":require('./img/ico-yhm.png')}})])])],1),_c('el-form-item',{attrs:{"prop":"password","rules":_vm.$validate({
                noNull: true,
                label: _vm.$t('base.password'),
                tips: _vm.$t('base.pleaseEnter'),
              })}},[_c('el-input',{attrs:{"placeholder":_vm.$t('base.passwordHolder'),"type":_vm.showPass ? 'text': 'password'},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.password"}},[_c('i',{staticClass:"iconfont iconMargin",attrs:{"slot":"prefix"},slot:"prefix"},[_c('img',{attrs:{"src":require('./img/ico-mima.png')}})]),_c('i',{class:[_vm.showPass ? 'showpass' : 'hidepass'],attrs:{"slot":"suffix","autocomplete":"auto"},on:{"click":function($event){_vm.showPass =! _vm.showPass}},slot:"suffix"},[(_vm.showPass)?[_c('img',{attrs:{"src":require("./img/passShow.png")}})]:[_c('img',{attrs:{"src":require("./img/passHide.png")}})]],2)])],1),_c('div',{staticClass:"forgotpassword"},[_c('router-link',{staticStyle:{"color":"#0B81E0","text-decoration":"none"},attrs:{"to":{name:'forgot'}}},[_vm._v(" "+_vm._s(_vm.$t('base.forgotpassword')))])],1),_c('el-form-item',[_c('el-button',{staticClass:"logonColor",attrs:{"type":"primary"},on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.$t('base.login'))+" ")]),_c('div',{staticClass:"homeFoot"},[_c('a',{staticClass:"homeJump",attrs:{"target":"_blank","href":_vm.customCompanyInfo
                    ? _vm.customCompanyInfo.contactLink
                    : _vm.$path.contactUsUrl}},[_c('i',{staticClass:"iconfont"}),_vm._v(_vm._s(_vm.customCompanyInfo ? _vm.customCompanyInfo.contactWord : _vm.$t('base.contactUs'))+" ")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('router-link',{staticClass:"homeReg",attrs:{"to":{name: 'accountReg' },"tag":"a","target":"_blank"}},[_c('i',{staticClass:"iconfont"}),_vm._v(_vm._s(_vm.$t('base.accountRegister')))]),_c('router-link',{staticClass:"homeReg",attrs:{"to":{name: 'reg' },"tag":"a","target":"_blank"}},[_c('i',{staticClass:"iconfont"}),_vm._v(_vm._s(_vm.$t('base.companyRegister')))])],1)])],1)],1)],1),_c('div',{staticClass:"footInfo"},[_c('p',{staticClass:"copyright"},[_vm._v(" "+_vm._s(_vm.customCompanyInfo ? _vm.customCompanyInfo.copyright : _vm.$t('base.copyright'))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }