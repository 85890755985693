// 公用字典
import i18n from '@/lang'
import * as util from '../utils'

// 网关操作日志
export const networkLogType = () => [
  {
    label: i18n.t('businessEnum.QUERY'), // lang:查询
    value: 'QUERY',
  },
  {
    label: i18n.t('businessEnum.RESET'), // lang:重置密码
    value: 'RESET',
  },
]

// 版本类型
export const versionType = () => [
  {
    label: i18n.t('businessEnum.MASTER'), // lang:主版本
    value: 'MASTER',
  },
  {
    label: i18n.t('businessEnum.HISTORY'), // lang:历史
    value: 'HISTORY',
  },
  {
    label: i18n.t('businessEnum.BETA'), // lang:beta
    value: 'BETA',
  },
]

// 终端类型
export const osType = () => [
  {
    label: i18n.t('businessEnum.APK'), // lang:安卓APK
    value: 'APK',
  },
  {
    label: i18n.t('businessEnum.PC'), // lang:PC应用
    value: 'PC',
  },
]

// 消息群体
export const messageTarget = () => [
  // {
  //   label: i18n.t('businessEnum.ALL'), // lang:全局
  //   value: 'ALL',
  // },
  {
    label: i18n.t('businessEnum.CHANNEL'), // lang:渠道线
    value: 'CHANNEL',
  },
  {
    label: i18n.t('businessEnum.USER_ACCOUNT'), // lang:用户账号
    value: 'USER_ACCOUNT',
  },
]

// 订阅模式
export const subscribeType = () => [
  {
    label: i18n.t('businessEnum.AUTO'), // lang:自动
    value: 'AUTO',
  },
  {
    label: i18n.t('businessEnum.MANUAL'), // lang:手动
    value: 'MANUAL',
  },
]

// // 用户终端类型
// export const userAccountType = () => [
//   {
//     label: i18n.t('businessEnum.SEATS'), // lang:座席端
//     value: 'SEATS',
//   },
//   {
//     label: i18n.t('businessEnum.AR'), // lang:AR端
//     value: 'AR',
//   },
//   {
//     label: i18n.t('businessEnum.MOBILE'), // lang:移动端
//     value: 'MOBILE',
//   },
// ]


// 用户账户类型
export const userAccountType = () => [
  {
    label: i18n.t('businessEnum.PC_USER'), // lang:PC端
    value: 'PC',
  },
  {
    label: i18n.t('businessEnum.WEB'), // lang:WEB端
    value: 'WEB',
  },
  {
    label: i18n.t('businessEnum.ANDROID'), // lang:手机端
    value: 'ANDROID',
  },
  {
    label: i18n.t('businessEnum.ANDROID_AR'), // lang:Android的AR端
    value: 'ANDROID_AR',
  },
  {
    label: i18n.t('businessEnum.WEB_ADMIN_USER'), // lang:管理端
    value: 'WEB_ADMIN',
  },
  {
    label: i18n.t('businessEnum.UNKNOWN'), // lang:未知
    value: 'UNKNOWN',
  },
]

// 可见范围
export const scopeEnum = () => [
  {
    label: i18n.t('businessEnum.GLOBAL'), // lang:全局可见
    value: 'GLOBAL',
  },
  {
    label: i18n.t('businessEnum.CHANNEL_LINE'), // lang:下级企业可见
    value: 'CHANNEL_LINE',
  },
  {
    label: i18n.t('businessEnum.scope_COMPANY'), // lang:下级企业可见
    value: 'COMPANY',
  },
]

// 层级
export const levelEnum = () => [
  {
    label: i18n.t('businessEnum.ONE'), // lang:一级
    value: 'ONE',
  },
  {
    label: i18n.t('businessEnum.TWO'), // lang:二级
    value: 'TWO',
  },
  {
    label: i18n.t('businessEnum.THREE'), // lang:三级
    value: 'THREE',
  },
  {
    label: i18n.t('businessEnum.FOUR'), // lang:四级
    value: 'FOUR',
  },
  {
    label: i18n.t('businessEnum.FIVE'), // lang:五级
    value: 'FIVE',
  },
  {
    label: i18n.t('businessEnum.SIX'), // lang:六级
    value: 'SIX',
  },
  {
    label: i18n.t('businessEnum.SEVEN'), // lang:七级
    value: 'SEVEN',
  },
  {
    label: i18n.t('businessEnum.EIGHT'), // lang:八级
    value: 'EIGHT',
  },
  {
    label: i18n.t('businessEnum.NINE'), // lang:九级
    value: 'NINE',
  },
  {
    label: i18n.t('businessEnum.TEN'), // lang:十级
    value: 'TEN',
  },
]

// AR设备地址池类型
export const arTypeEnum = () => [
  {
    label: i18n.t('businessEnum.MAC'), // lang:MAC地址
    value: 'MAC',
  },
  {
    label: i18n.t('businessEnum.OAID'), // lang:OAID地址
    value: 'OAID',
  },
]

// 计费模式
export const resourceFeeModeEnum = (noMetering) => {
  if(noMetering) {
    return [
      {
        label: i18n.t('businessEnum.ACCOUNT_SUBSCRIPTIONS'), // lang:账户订阅
        value: 'ACCOUNT_SUBSCRIPTIONS',
      },
    ]
  } else {
    return [
      {
        label: i18n.t('businessEnum.METERING'), // lang:按量付费
        value: 'METERING',
      },
      {
        label: i18n.t('businessEnum.ACCOUNT_SUBSCRIPTIONS'), // lang:账户订阅
        value: 'ACCOUNT_SUBSCRIPTIONS',
      },
    ]
  }
}

// 资源类型
export const resourceTypeEnum = (apiprefix) => {
  if(apiprefix === 'core') {
    return [
      {
        label: i18n.t('businessEnum.ACCOUNT_NUMBER'), // lang:账号数量
        value: 'ACCOUNT_NUMBER',
      },
    ]
  } else if (apiprefix === 'tmva') {
    return [
      // {
      //   label: i18n.t('businessEnum.ACCOUNT_NUMBER'), // lang:账号数量
      //   value: 'ACCOUNT_NUMBER',
      // },
      // {
      //   label: i18n.t('businessEnum.VIDEO_CALL_DURATION'), // lang:通话时长
      //   value: 'VIDEO_CALL_DURATION',
      // },
      // {
      //   label: i18n.t('businessEnum.ACCOUNT_SUBSCRIPTIONS_NUMBER'), // lang:账号订阅数量
      //   value: 'ACCOUNT_SUBSCRIPTIONS_NUMBER',
      // },
      {
        label: i18n.t('businessEnum.EQUIPMENT_NUMBER'), // lang:设备码数量
        value: 'EQUIPMENT_NUMBER',
      },
      {
        label: i18n.t('businessEnum.EQUIPMENT_COMPONENTS_NUMBER'), // lang:设备备件数量
        value: 'EQUIPMENT_COMPONENTS_NUMBER',
      },
      // {
      //   label: i18n.t('businessEnum.AR_DIGITAL_DISPLAY_NUMBER'), // lang:AR数显数量
      //   value: 'AR_DIGITAL_DISPLAY_NUMBER',
      // },
      {
        label: i18n.t('businessEnum.CLOUD_RECORDING_STORAGE_SPACE'), // lang:云录制空间
        value: 'CLOUD_RECORDING_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.REMAIN_CLOUD_RECORDING_STORAGE_SPACE'), // lang:剩余云录制空间
        value: 'REMAIN_CLOUD_RECORDING_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.OSS_STORAGE_SPACE'), // lang:云存储空间
        value: 'OSS_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.REMAIN_OSS_STORAGE_SPACE'), // lang:剩余云存储空间
        value: 'REMAIN_OSS_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.PRODUCT_KEY_NUMBER'), // lang:产品密钥数量
        value: 'PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_PRODUCT_KEY_NUMBER'), // lang:剩余产品密钥数量
        value: 'REMAIN_PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.THERMAL_IMAGERY_FUNCTION_KEY_NUMBER'), // lang:热成像功能密钥数量
        value: 'THERMAL_IMAGERY_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_THERMAL_IMAGERY_FUNCTION_KEY_NUMBER'), // lang:剩余热成像功能密钥数量
        value: 'REMAIN_THERMAL_IMAGERY_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.TEAM_VIEWER_FUNCTION_KEY_NUMBER'), // lang:远程控制功能密钥数量
        value: 'TEAM_VIEWER_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_TEAM_VIEWER_FUNCTION_KEY_NUMBER'), // lang:剩余远程控制功能密钥数量
        value: 'REMAIN_TEAM_VIEWER_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.SENSOR_COLLECT_FUNCTION_KEY_NUMBER'), // lang:传感器采集功能密钥数量
        value: 'SENSOR_COLLECT_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_SENSOR_COLLECT_FUNCTION_KEY_NUMBER'), // lang:剩余传感器采集成像功能密钥数量
        value: 'REMAIN_SENSOR_COLLECT_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.ENDOSCOPE_FUNCTION_KEY_NUMBER'), // lang:内窥镜功能密钥数量
        value: 'ENDOSCOPE_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_ENDOSCOPE_FUNCTION_KEY_NUMBER'), // lang:剩余内窥镜功能密钥数量
        value: 'REMAIN_ENDOSCOPE_FUNCTION_KEY_NUMBER',
      },
    ]
  } else if(apiprefix === 'workFlow') {
    return [
      {
        label: i18n.t('businessEnum.OSS_STORAGE_SPACE'), // lang:云存储空间
        value: 'OSS_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.REMAIN_OSS_STORAGE_SPACE'), // lang:云存储空间
        value: 'REMAIN_OSS_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.PRODUCT_KEY_NUMBER'), // lang:产品密钥数量
        value: 'PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_PRODUCT_KEY_NUMBER'), // lang:剩余产品密钥数量
        value: 'REMAIN_PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.WEB_EDIT_PRODUCT_KEY_NUMBER'), // lang:web端密钥数量
        value: 'WEB_EDIT_PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_WEB_EDIT_PRODUCT_KEY_NUMBER'), // lang:剩余web端密钥数量
        value: 'REMAIN_WEB_EDIT_PRODUCT_KEY_NUMBER',
      },
    ]
  } else if(apiprefix === 'tmvaAiStation') {
    return [
      {
        label: i18n.t('businessEnum.PRODUCT_KEY_NUMBER'), // lang:产品密钥数量
        value: 'PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_PRODUCT_KEY_NUMBER'), // lang:剩余产品密钥数量
        value: 'REMAIN_PRODUCT_KEY_NUMBER',
      }
    ]
  } else {
    return [
      {
        label: i18n.t('businessEnum.ACCOUNT_NUMBER'), // lang:账号数量
        value: 'ACCOUNT_NUMBER',
      },
      {
        label: i18n.t('businessEnum.VIDEO_CALL_DURATION'), // lang:通话时长
        value: 'VIDEO_CALL_DURATION',
      },
      {
        label: i18n.t('businessEnum.ACCOUNT_SUBSCRIPTIONS_NUMBER'), // lang:账号订阅数量
        value: 'ACCOUNT_SUBSCRIPTIONS_NUMBER',
      },
      {
        label: i18n.t('businessEnum.EQUIPMENT_NUMBER'), // lang:设备码数量
        value: 'EQUIPMENT_NUMBER',
      },
      {
        label: i18n.t('businessEnum.EQUIPMENT_COMPONENTS_NUMBER'), // lang:设备备件数量
        value: 'EQUIPMENT_COMPONENTS_NUMBER',
      },
      {
        label: i18n.t('businessEnum.AR_DIGITAL_DISPLAY_NUMBER'), // lang:AR数显数量
        value: 'AR_DIGITAL_DISPLAY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.CLOUD_RECORDING_STORAGE_SPACE'), // lang:云录制空间
        value: 'CLOUD_RECORDING_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.OSS_STORAGE_SPACE'), // lang:云存储空间
        value: 'OSS_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.PRODUCT_KEY_NUMBER'), // lang:产品密钥数量
        value: 'PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_PRODUCT_KEY_NUMBER'), // lang:剩余产品密钥数量
        value: 'REMAIN_PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.THERMAL_IMAGERY_FUNCTION_KEY_NUMBER'), // lang:热成像功能密钥数量
        value: 'THERMAL_IMAGERY_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_THERMAL_IMAGERY_FUNCTION_KEY_NUMBER'), // lang:剩余热成像功能密钥数量
        value: 'REMAIN_THERMAL_IMAGERY_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.TEAM_VIEWER_FUNCTION_KEY_NUMBER'), // lang:远程控制功能密钥数量
        value: 'TEAM_VIEWER_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_TEAM_VIEWER_FUNCTION_KEY_NUMBER'), // lang:剩余远程控制功能密钥数量
        value: 'REMAIN_TEAM_VIEWER_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.SENSOR_COLLECT_FUNCTION_KEY_NUMBER'), // lang:传感器采集功能密钥数量
        value: 'SENSOR_COLLECT_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_SENSOR_COLLECT_FUNCTION_KEY_NUMBER'), // lang:剩余传感器采集成像功能密钥数量
        value: 'REMAIN_SENSOR_COLLECT_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.ENDOSCOPE_FUNCTION_KEY_NUMBER'), // lang:内窥镜功能密钥数量
        value: 'ENDOSCOPE_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.REMAIN_ENDOSCOPE_FUNCTION_KEY_NUMBER'), // lang:剩余内窥镜功能密钥数量
        value: 'REMAIN_ENDOSCOPE_FUNCTION_KEY_NUMBER',
      },
    ]
  }
}
// 查询类型
export const resourceTypeEnumSelect = (apiprefix) => {
  console.log(apiprefix)
  if(apiprefix === 'core') {
    return [
      {
        label: i18n.t('businessEnum.ACCOUNT_NUMBER'), // lang:账号数量
        value: 'ACCOUNT_NUMBER',
      },
    ]
  } else if(apiprefix === 'tmva') {
    return [
      // {
      //   label: i18n.t('businessEnum.ACCOUNT_NUMBER'), // lang:账号数量
      //   value: 'ACCOUNT_NUMBER',
      // },
      // {
      //   label: i18n.t('businessEnum.VIDEO_CALL_DURATION'), // lang:通话时长
      //   value: 'VIDEO_CALL_DURATION',
      // },
      // {
      //   label: i18n.t('businessEnum.ACCOUNT_SUBSCRIPTIONS_NUMBER'), // lang:账号订阅数量
      //   value: 'ACCOUNT_SUBSCRIPTIONS_NUMBER',
      // },
      {
        label: i18n.t('businessEnum.EQUIPMENT_NUMBER'), // lang:设备码数量
        value: 'EQUIPMENT_NUMBER',
      },
      {
        label: i18n.t('businessEnum.EQUIPMENT_COMPONENTS_NUMBER'), // lang:设备备件数量
        value: 'EQUIPMENT_COMPONENTS_NUMBER',
      },
      // {
      //   label: i18n.t('businessEnum.AR_DIGITAL_DISPLAY_NUMBER'), // lang:AR数显数量
      //   value: 'AR_DIGITAL_DISPLAY_NUMBER',
      // },
      {
        label: i18n.t('businessEnum.CLOUD_RECORDING_STORAGE_SPACE'), // lang:云录制空间
        value: 'CLOUD_RECORDING_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.OSS_STORAGE_SPACE'), // lang:云存储空间
        value: 'OSS_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.PRODUCT_KEY_NUMBER'), // lang:产品密钥数量
        value: 'PRODUCT_KEY_NUMBER',
      },
      // {
      //   label: i18n.t('businessEnum.REMAIN_PRODUCT_KEY_NUMBER'), // lang:剩余产品密钥数量
      //   value: 'REMAIN_PRODUCT_KEY_NUMBER',
      // },
      {
        label: i18n.t('businessEnum.THERMAL_IMAGERY_FUNCTION_KEY_NUMBER'), // lang:热成像功能密钥数量
        value: 'THERMAL_IMAGERY_FUNCTION_KEY_NUMBER',
      },
      // {
      //   label: i18n.t('businessEnum.REMAIN_THERMAL_IMAGERY_FUNCTION_KEY_NUMBER'), // lang:剩余热成像功能密钥数量
      //   value: 'REMAIN_THERMAL_IMAGERY_FUNCTION_KEY_NUMBER',
      // },
      {
        label: i18n.t('businessEnum.TEAM_VIEWER_FUNCTION_KEY_NUMBER'), // lang:远程控制功能密钥数量
        value: 'TEAM_VIEWER_FUNCTION_KEY_NUMBER',
      },
      // {
      //   label: i18n.t('businessEnum.REMAIN_TEAM_VIEWER_FUNCTION_KEY_NUMBER'), // lang:剩余远程控制功能密钥数量
      //   value: 'REMAIN_TEAM_VIEWER_FUNCTION_KEY_NUMBER',
      // },
      {
        label: i18n.t('businessEnum.SENSOR_COLLECT_FUNCTION_KEY_NUMBER'), // lang:传感器采集功能密钥数量
        value: 'SENSOR_COLLECT_FUNCTION_KEY_NUMBER',
      },
      // {
      //   label: i18n.t('businessEnum.REMAIN_SENSOR_COLLECT_FUNCTION_KEY_NUMBER'), // lang:剩余传感器采集成像功能密钥数量
      //   value: 'REMAIN_SENSOR_COLLECT_FUNCTION_KEY_NUMBER',
      // },
      {
        label: i18n.t('businessEnum.ENDOSCOPE_FUNCTION_KEY_NUMBER'), // lang:内窥镜功能密钥数量
        value: 'ENDOSCOPE_FUNCTION_KEY_NUMBER',
      },
      // {
      //   label: i18n.t('businessEnum.REMAIN_ENDOSCOPE_FUNCTION_KEY_NUMBER'), // lang:剩余内窥镜功能密钥数量
      //   value: 'REMAIN_ENDOSCOPE_FUNCTION_KEY_NUMBER',
      // },
    ]
  } else if(apiprefix === 'workFlow') {
    return [
      {
        label: i18n.t('businessEnum.OSS_STORAGE_SPACE'), // lang:云存储空间
        value: 'OSS_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.PRODUCT_KEY_NUMBER'), // lang:产品密钥数量
        value: 'PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.WEB_EDIT_PRODUCT_KEY_NUMBER'), // lang:编辑端密钥数量
        value: 'WEB_EDIT_PRODUCT_KEY_NUMBER',
      },
    ]
  } else if (apiprefix === 'tmvaAiStation') {
    return [
      {
        label: i18n.t('businessEnum.PRODUCT_KEY_NUMBER'), // lang:产品密钥数量
        value: 'PRODUCT_KEY_NUMBER',
      },
    ]
  } else {
    return [
      {
        label: i18n.t('businessEnum.ACCOUNT_NUMBER'), // lang:账号数量
        value: 'ACCOUNT_NUMBER',
      },
      {
        label: i18n.t('businessEnum.VIDEO_CALL_DURATION'), // lang:通话时长
        value: 'VIDEO_CALL_DURATION',
      },
      {
        label: i18n.t('businessEnum.EQUIPMENT_NUMBER'), // lang:设备码数量
        value: 'EQUIPMENT_NUMBER',
      },
      {
        label: i18n.t('businessEnum.EQUIPMENT_COMPONENTS_NUMBER'), // lang:设备备件数量
        value: 'EQUIPMENT_COMPONENTS_NUMBER',
      },
      {
        label: i18n.t('businessEnum.AR_DIGITAL_DISPLAY_NUMBER'), // lang:AR数显数量
        value: 'AR_DIGITAL_DISPLAY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.CLOUD_RECORDING_STORAGE_SPACE'), // lang:云录制空间
        value: 'CLOUD_RECORDING_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.OSS_STORAGE_SPACE'), // lang:云存储空间
        value: 'OSS_STORAGE_SPACE',
      },
      {
        label: i18n.t('businessEnum.PRODUCT_KEY_NUMBER'), // lang:产品密钥数量
        value: 'PRODUCT_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.THERMAL_IMAGERY_FUNCTION_KEY_NUMBER'), // lang:热成像功能密钥数量
        value: 'THERMAL_IMAGERY_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.TEAM_VIEWER_FUNCTION_KEY_NUMBER'), // lang:远程控制功能密钥数量
        value: 'TEAM_VIEWER_FUNCTION_KEY_NUMBER',
      },

      {
        label: i18n.t('businessEnum.SENSOR_COLLECT_FUNCTION_KEY_NUMBER'), // lang:传感器采集功能密钥数量
        value: 'SENSOR_COLLECT_FUNCTION_KEY_NUMBER',
      },
      {
        label: i18n.t('businessEnum.ENDOSCOPE_FUNCTION_KEY_NUMBER'), // lang:内窥镜功能密钥数量
        value: 'ENDOSCOPE_FUNCTION_KEY_NUMBER',
      },
    ]
  }
}

// 授权分配
export const assignStatusEnum = () => [
  {
    label: i18n.t('businessEnum.ASSIGNED'), // lang:已授权
    value: 'ASSIGNED',
  },
  {
    label: i18n.t('businessEnum.ROLLBACK'), // lang:已撤销
    value: 'ROLLBACK',
  },
]

// 分配账单
export const assignStatusEnum_1 = () => [
  {
    label: i18n.t('businessEnum.ASSIGNED_1'), // lang:已分配
    value: 'ASSIGNED',
  },
  {
    label: i18n.t('businessEnum.ROLLBACK_1'), // lang:已撤销
    value: 'ROLLBACK',
  },
]

// 语言类型
export const language = () => [
  {
    label: i18n.t('businessEnum.zhCN'), // lang:中文
    value: 'zhCN',
  },
  {
    label: i18n.t('businessEnum.en'), // lang:英文
    value: 'en',
  },
]

// 发票类型
export const invoiceType = () => [
  {
    label: i18n.t('businessEnum.GENERAL'), // lang:普票
    value: 'GENERAL',
  },
  {
    label: i18n.t('businessEnum.VAT'), // lang:增值税发票
    value: 'VAT',
  },
]

// 字典类型code
export const dictTypeEnum = () => [
  {
    label: i18n.t('businessEnum.COMPANY_INDUSTRY'), // lang:行业
    value: 'COMPANY_INDUSTRY',
    icon: 'el-icon-connection',
  },
  {
    label: i18n.t('businessEnum.ADDRESS'), // lang:地址
    value: 'ADDRESS',
    icon: 'el-icon-location-information',
  },
  {
    label: i18n.t('businessEnum.DOCUMENT_TYPE'), // lang:文档管理
    value: 'DOCUMENT_TYPE',
    icon: 'el-icon-location-information',
  },
  {
    label: i18n.t('businessEnum.VERSION_CATEGORY'), // lang:版本分类
    value: 'VERSION_CATEGORY',
    icon: 'el-icon-tickets',
  },
  {
    label: i18n.t('businessEnum.WORK_FLOW_STEP_GROUP'), // lang:版本分类
    value: 'WORK_FLOW_STEP_GROUP',
    icon: '',
  },
  // {
  //   label: i18n.t('businessEnum.CLIENT_LOG_TYPE'), // lang:终端日志类型
  //   value: 'CLIENT_LOG_TYPE',
  //   icon: 'el-icon-suitcase-1',
  // },
]
// 字典类型TMVA
export const dictTypeEnumTMVA = () => [
  {
    label: i18n.t('businessEnum.DOCUMENT_TYPE'), // lang:文档管理
    value: 'DOCUMENT_TYPE',
    icon: 'el-icon-location-information',
  },
  {
    label: i18n.t('businessEnum.VERSION_CATEGORY'), // lang:版本分类
    value: 'VERSION_CATEGORY',
    icon: 'el-icon-tickets',
  },
]

export const dictTypeEnumCORE = () => [
  {
    label: i18n.t('businessEnum.COMPANY_INDUSTRY'), // lang:行业
    value: 'COMPANY_INDUSTRY',
    icon: 'el-icon-connection',
  },
  {
    label: i18n.t('businessEnum.ADDRESS'), // lang:地址
    value: 'ADDRESS',
    icon: 'el-icon-location-information',
  },
  {
    label: i18n.t('businessEnum.DOCUMENT_TYPE'), // lang:文档管理
    value: 'DOCUMENT_TYPE',
    icon: 'el-icon-location-information',
  },
]


// 状态
export const enable = () => [
  {
    label: i18n.t('businessEnum.NORMAL'), // lang:正常
    value: 'NORMAL',
  },
  {
    label: i18n.t('businessEnum.DISABLE'), // lang:禁用
    value: 'DISABLE',
  },
  {
    label: i18n.t('businessEnum.DELETE'), // lang:删除
    value: 'DELETE',
  },
]

// 是否
export const yesOrNo = () => [
  {
    label: i18n.t('businessEnum.YES'), // lang:是
    value: 'YES',
  },
  {
    label: i18n.t('businessEnum.NO'), // lang:否
    value: 'NO',
  },
]

export const labelType = () => [
  {
    label: i18n.t('labelManage.bleType'), // lang:蓝牙标签
    value: 'ble',
  },
  {
    label: i18n.t('labelManage.RFIDType'), // lang:RFID标签
    value: 'rfid',
  },
]

// 功能类型
export const permissionType = () => [
  {
    label: i18n.t('businessEnum.ROOT'), // lang:根目录
    value: 'ROOT',
  },
  {
    label: i18n.t('businessEnum.ONE_LIST'), // lang:一级功能
    value: 'ONE_LIST',
  },
  {
    label: i18n.t('businessEnum.TWO_LIST'), // lang:二级功能
    value: 'TWO_LIST',
  },
  {
    label: i18n.t('businessEnum.BUTTON'), // lang:按钮功能
    value: 'BUTTON',
  },
]

// 角色类型
export const roleType = () => [
  {
    label: i18n.t('businessEnum.COMPANY_MANAGER'), // lang:企业管理员
    value: 'COMPANY_MANAGER',
  },
  {
    label: i18n.t('businessEnum.SUB_MANAGER'), // lang:子管理员
    value: 'SUB_MANAGER',
  },
]
//账号类型
export const accountType = () => [
  {
    label: i18n.t('businessEnum.SYSTEM_ADMINISTRATOR'), // lang:系统超管
    value: 'SYSTEM_ADMINISTRATOR',
  },
  {
    label: i18n.t('businessEnum.MANAGER'), // lang:管理员
    value: 'MANAGER',
  },
  {
    label: i18n.t('businessEnum.USER_ACCOUNT_NEW'), // lang:用户账户
    value: 'USER_ACCOUNT',
  },
]

// 会话来源
export const contextFrom = () => [
  {
    label: i18n.t('businessEnum.REQUEST'), // lang:外部请求
    value: 'REQUEST',
  },
  {
    label: i18n.t('businessEnum.SCHEDULE'), // lang:定时任务
    value: 'SCHEDULE',
  },
  {
    label: i18n.t('businessEnum.WEB_SOCKET'), // lang:webSocket
    value: 'WEB_SOCKET',
  },
]

// 是否
export const prefixHttp = () => [
  {
    label: i18n.t('businessEnum.Http'), // lang:是
    value: 'http',
  },
  {
    label: i18n.t('businessEnum.Https'), // lang:否
    value: 'https',
  },
]

export const workflowProductVersion = () => {
  return [
    {
      label: i18n.t('businessEnum.NORMAL1'), // lang:普通
      value: 'NORMAL',
    },
    {
      label: i18n.t('businessEnum.SPOT_INSPECTION'), // lang:点巡检
      value: 'SPOT_INSPECTION',
    },
    {
      label: i18n.t('businessEnum.ASSEMBLY_INSTRUCTION'), // lang:装配指导
      value: 'ASSEMBLY_INSTRUCTION',
    },
    {
      label: i18n.t('businessEnum.PRACTICAL_TRAINING_EDUCATION'), // lang:实训教育
      value: 'PRACTICAL_TRAINING_EDUCATION',
    },
    {
      label: i18n.t('businessEnum.SAFETY_MANAGEMENT'), // lang:安全管理
      value: 'SAFETY_MANAGEMENT',
    },
  ]
}
// 密钥类型
export const secretKeyType = () => {
  if(util.getLocalStorage('apiprefix') === 'workFlow') {
    return [
      {
        label: i18n.t('businessEnum.PRODUCT_KEY'), // lang:产品密钥
        value: 'PRODUCT_KEY',
      },
      {
        label: i18n.t('businessEnum.FUNCTION_KEY'), // lang:功能密钥
        value: 'FUNCTION_KEY',
      },
      // {
      //   label: i18n.t('businessEnum.WEB_EDIT_PRODUCT_KEY'), // lang:产品密钥
      //   value: 'WEB_EDIT_PRODUCT_KEY',
      // },
    ]
  } else if (util.getLocalStorage('apiprefix') === 'tmvaAiStation') {
    return [
      {
        label: i18n.t('businessEnum.PRODUCT_KEY'), // lang:产品密钥
        value: 'PRODUCT_KEY',
      },
    ]
  } else {
    return [
      {
        label: i18n.t('businessEnum.PRODUCT_KEY'), // lang:产品密钥
        value: 'PRODUCT_KEY',
      },
      {
        label: i18n.t('businessEnum.FUNCTION_KEY'), // lang:功能密钥
        value: 'FUNCTION_KEY',
      },
    ]
  }
}
// 功能密钥
export const functionKeyType = () => {
  if(util.getLocalStorage('apiprefix') === 'tmva') {
    return [
      {
        label: i18n.t('businessEnum.THERMAL_IMAGERY_FUNCTION_KEY'), // lang:热成像
        value: 'THERMAL_IMAGERY_FUNCTION_KEY',
      },
      {
        label: i18n.t('businessEnum.TEAM_VIEWER_FUNCTION_KEY'), // lang:远程控制
        value: 'TEAM_VIEWER_FUNCTION_KEY',
      },
      {
        label: i18n.t('businessEnum.SENSOR_COLLECT_FUNCTION_KEY'), // lang:传感器采集
        value: 'SENSOR_COLLECT_FUNCTION_KEY',
      },
      {
        label: i18n.t('businessEnum.ENDOSCOPE_FUNCTION_KEY'), // lang:内窥镜
        value: 'ENDOSCOPE_FUNCTION_KEY',
      },
    ]
  } else if(util.getLocalStorage('apiprefix') === 'workFlow') {
    return  [
      {
        label: i18n.t('businessEnum.WEB_EDIT_PRODUCT_KEY'), // lang:热成像
        value: 'WEB_EDIT_PRODUCT_KEY',
      },
    ]
  }
}
// isBackOut  是否撤回
export const showBackOut = () => [
  {
    label: i18n.t('businessEnum.ASSIGNED_1'), // lang:已分配
    value: 'NO',
  },
  {
    label: i18n.t('businessEnum.ROLLBACK_1'), // lang:已撤销
    value: 'YES',
  },
]
//  是否激活
export const showActivation = () => [
  {
    label: i18n.t('businessEnum.ASSIGNED_2'), // lang:已激活
    value: 'YES',
  },
  {
    label: i18n.t('businessEnum.ROLLBACK_2'), // lang:未激活
    value: 'NO',
  },
]
// 密钥类型
export const showSecretKey = () => [
  {
    label: i18n.t('businessEnum.REMOTE_GUIDANCE'), // lang:远程指导
    value: 'PRODUCT_KEY',
  },
  {
    label: i18n.t('businessEnum.THERMAL_IMAGERY_FUNCTION_KEY'), // lang:热成像
    value: 'THERMAL_IMAGERY_FUNCTION_KEY',
  },
  {
    label: i18n.t('businessEnum.TEAM_VIEWER_FUNCTION_KEY'), // lang:远程控制
    value: 'TEAM_VIEWER_FUNCTION_KEY',
  },
  {
    label: i18n.t('businessEnum.SENSOR_COLLECT_FUNCTION_KEY'), // lang:传感器采集
    value: 'SENSOR_COLLECT_FUNCTION_KEY',
  },
  {
    label: i18n.t('businessEnum.ENDOSCOPE_FUNCTION_KEY'), // lang:内窥镜
    value: 'ENDOSCOPE_FUNCTION_KEY',
  },
]

export const deviceDict = () => [
  {
    label: i18n.t('deviceEnum.ANDROID_DEVICE'), // lang:安卓
    value: 'ANDROID_DEVICE',
  }
]

export const netRequestType = () => [
  {
    label: i18n.t('netRequestTypeEnum.GET'), // lang:GET
    value: 'GET',
  },
  {
    label: i18n.t('netRequestTypeEnum.POST'), // lang:GET
    value: 'POST',
  }
]

export const apiRuleType = () => [
  {
    label: i18n.t('apiRuleTypeEnum.READ'), // lang:GET
    value: 'READ',
  },
  {
    label: i18n.t('apiRuleTypeEnum.WRITE'), // lang:GET
    value: 'WRITE',
  }
]

export const dataFormTypeEnum = () => [
  {
    label: 'Boolean',
    value: 'BOOLEAN_FORM',
  },
  {
    label: 'Integer',
    value: 'INTEGER_FORM',
  },
  {
    label: 'Double',
    value: 'DOUBLE_FORM',
  },
  {
    label: 'Long',
    value: 'LONG_FORM',
  },
  {
    label: 'Number',
    value: 'NUMBER_FORM',
  },
  {
    label: 'String',
    value: 'STRING_FORM',
  },
  {
    label: 'Object',
    value: 'OBJECT_FORM',
  },
  {
    label: 'Array',
    value: 'ARRAY_FORM',
  },
  {
    label: 'ImageBase64',
    value: 'IMAGE_BASE64',
  },
]

export const communicationMode = () => [
  {
    label: i18n.t('communicationModeEnum.TYPE_C'), // lang:type-c
    value: 'TYPE_C',
  },
  {
    label: i18n.t('communicationModeEnum.BLUETOOTH'), // lang:蓝牙
    value: 'BLUETOOTH',
  }
]

export const dataFormType = () => [
  {
    label: i18n.t('dataFormEnum.BOOLEAN_FORM'), // lang:GET
    value: 'BOOLEAN_FORM',
  },
  {
    label: i18n.t('dataFormEnum.INTEGER_FORM'), // lang:GET
    value: 'INTEGER_FORM',
  },
  {
    label: i18n.t('dataFormEnum.DOUBLE_FORM'), // lang:GET
    value: 'DOUBLE_FORM',
  },
  {
    label: i18n.t('dataFormEnum.LONG_FORM'), // lang:GET
    value: 'LONG_FORM',
  },
  {
    label: i18n.t('dataFormEnum.NUMBER_FORM'), // lang:GET
    value: 'NUMBER_FORM',
  },
  {
    label: i18n.t('dataFormEnum.STRING_FORM'), // lang:GET
    value: 'STRING_FORM',
  },  {
    label: i18n.t('dataFormEnum.OBJECT_FORM'), // lang:GET
    value: 'OBJECT_FORM',
  },
  {
    label: i18n.t('dataFormEnum.ARRAY_FORM'), // lang:GET
    value: 'ARRAY_FORM',
  },

]

export const algorithmType = () => [
  // {
  //   label: i18n.t('algorithmEnum.SUBSTATION_SWITCH')+'(SUBSTATION_SWITCH)', // lang:GET
  //   value: 'SUBSTATION_SWITCH',
  // },
  // {
  //   label: i18n.t('algorithmEnum.ORDINARY_POINTER_PRESSURE_GAUGE')+'(ORDINARY_POINTER_PRESSURE_GAUGE)', // lang:GET
  //   value: 'ORDINARY_POINTER_PRESSURE_GAUGE',
  // },
  // {
  //   label: i18n.t('algorithmEnum.SHOCK_RESISTANT_POINTER_PRESSURE_GAUGE')+'(SHOCK_RESISTANT_POINTER_PRESSURE_GAUGE)', // lang:GET
  //   value: 'SHOCK_RESISTANT_POINTER_PRESSURE_GAUGE',
  // },
  // {
  //   label: i18n.t('algorithmEnum.ORDINARY_DIGITAL_PRESSURE_GAUGE')+ '(ORDINARY_DIGITAL_PRESSURE_GAUGE)', // lang:GET
  //   value: 'ORDINARY_DIGITAL_PRESSURE_GAUGE',
  // },
  // {
  //   label: i18n.t('algorithmEnum.API') + '(API)', // lang:GET
  //   value: 'API',
  // },
  {
    label: i18n.t('algorithmEnum.AI_STATION')+ '(AI_STATION)', // lang:GET
    value: 'AI_STATION',
  },
  {
    label: i18n.t('algorithmEnum.EXTERNAL_AI_IMG')+ '(EXTERNAL_AI_IMG)', // lang:GET
    value: 'EXTERNAL_AI_IMG',
  },
  {
    label: i18n.t('algorithmEnum.EXTERNAL_AI_VIDEO')+ '(EXTERNAL_AI_VIDEO)', // lang:GET
    value: 'EXTERNAL_AI_VIDEO',
  },
]

export const workflowPermissionEnum = () => {
  return [
      'PERMISSION_MANAGE',
      'SPOT_INSPECTION_PERMISSION_MANAGE',
      'ASSEMBLY_INSTRUCTION_PERMISSION_MANAGE',
      'PRACTICAL_TRAINING_EDUCATION_PERMISSION_MANAGE',
      'SAFETY_MANAGEMENT_PERMISSION_MANAGE',
  ]
}

export const workflowPermissionEnumMap = () => {
  return {
    NORMAL: 'PERMISSION_MANAGE',
    SPOT_INSPECTION: 'SPOT_INSPECTION_PERMISSION_MANAGE',
    ASSEMBLY_INSTRUCTION: 'ASSEMBLY_INSTRUCTION_PERMISSION_MANAGE',
    PRACTICAL_TRAINING_EDUCATION: 'PRACTICAL_TRAINING_EDUCATION_PERMISSION_MANAGE',
    SAFETY_MANAGEMENT: 'SAFETY_MANAGEMENT_PERMISSION_MANAGE',
  }
}

export const productTypeEnum = () => [
  {
    label: i18n.t('businessEnum.TMVA'),
    value: 'TMVA'
  },
  {
    label: i18n.t('businessEnum.XR_WORK'),
    value: 'XR_WORK'
  }
]


