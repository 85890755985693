<template>
  <div class="mainLeftNavs" :class="{ isFold: $store.state.isFold }">
    <div class="logoWrap"  @click="getMain">
<!--      <div-->
<!--        class="iconImg logo"-->
<!--        :style="{-->
<!--          backgroundImage:-->
<!--            'url(' +-->
<!--            (customCompanyInfo-->
<!--              ? customCompanyInfo.logo.fileHost +-->
<!--                '/' +-->
<!--                customCompanyInfo.logo.objectName-->
<!--              : require('./../img/logo.png')) +-->
<!--            ')',-->
<!--        }"-->
<!--      />-->
      <img
          class="iconImg logo"
          :src="require('../../main/img/' + $util.getLocalStorage('productType') + '.png')"
      />
    </div>
    <div class="leftMenus">
      <el-menu
        background-color="transparent"
        text-color="#676971"
        active-text-color="#ffffff"
        :default-active="activeTag"
        :collapse="$store.state.isFold"
        @select="select"
      >
        <template v-for="item in permission">
          <!-- 远程专家指导套件-->
          <template v-if="$util.getLocalStorage('apiprefix') === 'tmva'">
            <template
              v-if="
                item.children &&
                item.children.length &&
                item.children[0].permissionType === 'TWO_LIST'
              "
            >
              <el-submenu
                :key="item.permissionEnum"
                :index="`${item.permissionEnum}`"
              >
                <template slot="title">
                  <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    v-for="list in item.children"
                    :key="list.permissionEnum"
                    :index="`${list.permissionEnum}`"
                    >{{ list.name }}</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item
                :key="item.permissionEnum"
                :index="`${item.permissionEnum}`"
              >
                <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </template>
          </template>
          <!-- 账号管理 -->
          <template v-if="$util.getLocalStorage('apiprefix') === 'core'">
            <template
              v-if="
                item.children &&
                item.children.length &&
                item.children[0].permissionType === 'TWO_LIST'
              "
            >
              <el-submenu
                :key="item.permissionEnum"
                :index="`${item.permissionEnum}`"
              >
                <template slot="title">
                  <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    v-for="list in item.children"
                    :key="list.permissionEnum"
                    :index="`${list.permissionEnum}`"
                    >{{ list.name }}</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item
                :key="item.permissionEnum"
                :index="`${item.permissionEnum}`"
              >
                <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </template>
          </template>
          <!-- SOP执行与追溯套件 -->
          <template v-if="$util.getLocalStorage('apiprefix') === 'workFlow'">
            <template v-if="isShow(item)">
              <template
                  v-if="
                  item.children &&
                  item.children.length &&
                  item.children[0].permissionType === 'TWO_LIST'
                "
              >
                <el-submenu
                    :key="item.permissionEnum"
                    :index="`${item.permissionEnum}`"
                >
                  <template slot="title">
                    <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                    <span>{{ item.name }}</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item
                        v-for="list in item.children"
                        :key="list.permissionEnum"
                        :index="`${list.permissionEnum}`"
                    >{{ list.name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item
                    :key="item.permissionEnum"
                    :index="`${item.permissionEnum}`"
                >
                  <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                  <span slot="title">{{ item.name }}</span>
                </el-menu-item>
              </template>
            </template>
          </template>
          <!-- AI算法能力开放套件 -->
          <template v-if="$util.getLocalStorage('apiprefix') === 'tmvaAiStation'">
            <template>
              <template
                  v-if="
                  item.children &&
                  item.children.length &&
                  item.children[0].permissionType === 'TWO_LIST'
                "
              >
                <el-submenu
                    :key="item.permissionEnum"
                    :index="`${item.permissionEnum}`"
                >
                  <template slot="title">
                    <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                    <span>{{ item.name }}</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item
                        v-for="list in item.children"
                        :key="list.permissionEnum"
                        :index="`${list.permissionEnum}`"
                    >{{ list.name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item
                    :key="item.permissionEnum"
                    :index="`${item.permissionEnum}`"
                >
                  <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                  <span slot="title">{{ item.name }}</span>
                </el-menu-item>
              </template>
            </template>
          </template>
          <!-- 传感及数据接口管理 -->
          <template v-if="$util.getLocalStorage('apiprefix') === 'idc'">
            <template
                v-if="
                item.children &&
                item.children.length &&
                item.children[0].permissionType === 'TWO_LIST'
              "
            >
              <el-submenu
                  :key="item.permissionEnum"
                  :index="`${item.permissionEnum}`"
              >
                <template slot="title">
                  <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                      v-for="list in item.children"
                      :key="list.permissionEnum"
                      :index="`${list.permissionEnum}`"
                  >{{ list.name }}</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item
                  :key="item.permissionEnum"
                  :index="`${item.permissionEnum}`"
              >
                <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </template>
          </template>
          <!-- 设备模块 -->
          <template v-if="$util.getLocalStorage('apiprefix') === 'device'">
            <template
              v-if="
                item.children &&
                item.children.length &&
                item.children[0].permissionType === 'TWO_LIST'
              "
            >
              <el-submenu
                :key="item.permissionEnum"
                :index="`${item.permissionEnum}`"
              >
                <template slot="title">
                  <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    v-for="list in item.children"
                    :key="list.permissionEnum"
                    :index="`${list.permissionEnum}`"
                    >{{ list.name }}</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item
                :key="item.permissionEnum"
                :index="`${item.permissionEnum}`"
              >
                <i :class="`iconfont ${item.icon || 'icon-set'}`" />
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </template>
          </template>
        </template>
      </el-menu>
    </div>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
