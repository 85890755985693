// 企业OEM管理
export default {
    leftTitle: '产品分类',
    title: 'OEM列表',
    targetCompany: '所属企业',
    webUrl: '访问地址',
    productName: '产品名称',
    productZhName: '产品名称-中文',
    productEnName: '产品名称-英文',
    productLogo: '产品LOGO',
    status: '状态',
    productLogoZhImgId: '产品LOGO-中文',
    productLogoEnImgId: '产品LOGO-英文',
    companyLogoImgId: '企业LOGO',
    welcomeZhText: '欢迎语-中文',
    welcomeEnText: '欢迎语-英文',
    copyrightZhText: '版权信息-中文',
    copyrightEnText: '版权信息-英文',
    connectUsType: '联系我们-展示方式',
    text: '弹窗文本展示',
    url: '跳转连接展示',
    contactUsTel: '联系我们-电话',
    contactUsMail: '联系我们-邮箱',
    companyName: '联系我们-公司名称',
    companyWebUrl: '联系我们-公司网址',
    companyAddress: '联系我们-公司地址',
    contactUsUrl: '联系我们-跳转地址',
    productSloganZh: '产品标语-中文',
    productSloganEn: '产品标语-英文',
    productSceneZhWebImgId: 'WEB场景图-中文',
    productSceneEnWebImgId: 'WEB场景图-英文',
    productSceneZhAndroidImgId: '安卓场景图-中文',
    productSceneEnAndroidImgId: '安卓场景图-英文',
    androidHelper: '安卓端帮助信息',
    xrWorkVersionConfigs: '版本OEM配置',
    xrWorkType: '工作流产品版本',
    loginBgZhImgId: '登陆页面背景图-中文',
    loginBgEnImgId: '登陆页面背景图-英文',
    versionZhName: '版本名称-中文',
    versionEnName: '版本名称-英文',
    suggestedResolution: '分辨率',
    resolutionNotUpToStandard: '上传图片的分辨率不符合规范',
}
