// 模板管理
export default {
    title:'模板列表',
    templateTitle: '模板标题',
    coverImg: '模板图标',
    templateType: '模板类型枚举',
    stepName: '模板名称',
    middleElements: '中部元素',
    bottomElements: '底部元素',
    stepGroup: '模板分组',
    serialNumber: '模板编号',
    productType: '产品版本',
    selectProductType: '请选择产品版本',
    category: '模板分类',
    all: '全部模板'
}
