export default {
    name: '名称',
    title: '算法平台列表',
    selectApi: '请选择API',
    apiName: 'API名称',
    recognitionStartApiId: '识别预加载API',
    recognitionEndApiId: '识别资源释放API',
    recognitionAlgorithmIdApiId: '识别算法ID列表API',
    recognitionAlgorithmRecognitionVersionsApiId: '识别算法ID版本API',
    detail2: {
        name: '名称(接口名称、接口数据名称)',
        apiName: '接口名称',
        title: '示例',
        address: 'API地址',
        algorithmType: '算法类型',
        requestMethod: '请求方式',
        requestAddress: '请求地址',
        requestHeader: '请求头',
        requestHeaderContent: '无',
        requestHeaderTip: '如需添加请至接口管理页面',
        requestBody: '请求体',
        requestBodyContent: '{"aiStationId": "${aiStationId}", "imgBase64": "${imgBase64}"}',
        requestBodyTip: '如需添加固定请求参数,请至接口管理页面,aiStationId和imgBase64两者不可修改',
        responseBody: '返回体',
        responseBodyContent: '{"state": "SUCCESS"}',
    }
}
