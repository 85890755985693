<template>
<div class="page">
  <div class="pageBox">
    <el-row class="pageTop">
      <el-col :span="2">
          <el-select
            v-model="lang"
            placeholder="请选择"
            size="mini"
            style="width: 100px"
            @change="changeLang"
          >
          <el-option
            v-for="item in langData"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
          </el-select>
        </el-col>
      <el-col :span="12" :offset="4" v-if="stepTop">
        <div class="stepBox">
          <div class="item" :class="[stepNum < 4 ? 'active' : 'grey']"><span>1</span>{{$t('reg.affiliateSignup')}}</div>
          <div class="item"><img src="../../assets/jiantou.png" /></div>
          <div class="item" :class="[stepNum > 1 || stepNum === 2 ? 'active' : 'grey']"><span>2</span>{{$t('reg.selectproductmodule')}}</div>
          <div class="item"><img src="../../assets/jiantou.png" /></div>
          <div class="item" :class="[stepNum === 3 ? 'active' : 'grey']"><span>3</span>{{$t('reg.selectserviceprovider')}}</div>
        </div>
      </el-col>
      <el-col :span="1" :offset="3">
<!--        <div class="back" @click="getBack">{{$t('reg.goback')}}</div>-->
      </el-col>
    </el-row>
    <template v-if="stepOne">
    <el-form ref="dataForm" :model="ruleForm" :rules="rules" label-width="140px" >
      <el-row :gutter="20">
        <el-col :span="10" :offset="7">
          <div class="formBox">
            <el-form-item :label=" $t('reg.company')" prop="companyName"
              :rules="
                  $validate({
                    noNull: true,
                    label: $t('reg.company'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <el-input v-model="ruleForm.companyName" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.company')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.companycode')" prop="creditCodeForInvoice"
                  :rules="
                      $validate({
                        noNull: true,
                        type:'maxlength',
                        label: $t('reg.companycode'),
                        tips: $t('base.pleaseEnter'),
                      })">
                    <el-input v-model="ruleForm.creditCodeForInvoice" size="mini" maxlength="18" :placeholder="$t('base.pleaseEnter') + $t('reg.companycode')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.industry')" prop="industryId" >
              <el-cascader
                style="width: 310px"
                ref="industryLabel"
                v-model="ruleForm.industryId"
                :options="ruleForm.industryList"
                :show-all-levels="true"
                @change="changeIndustry"
                :props="{
                  value: 'id',
                  label: 'value',
                  emitPath:false
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item v-if="ruleForm.industryId === null" :label="$t('reg.customIndustry')" prop="businessScope"
                          :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.customIndustry'),
                  tips: $t('reg.pleaseFillin'),
                })">
              <el-input v-model="ruleForm.customIndustry" size="mini" :placeholder="$t('reg.pleaseFillin') + $t('reg.customIndustry')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.business')" prop="businessScope"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.business'),
                  tips: $t('reg.pleaseBrief'),
                })">
              <el-input v-model="ruleForm.businessScope" size="mini" :placeholder="$t('reg.pleaseBrief') + $t('reg.business')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.address')"  prop="addressId">
              <el-cascader
                style="width: 310px"
                ref="addressLabel"
                v-model="ruleForm.addressId"
                :options="ruleForm.addressData"
                 :show-all-levels="true"
                @change="changeAdress"
                :props="{
                  value: 'id',
                  label: 'value',
                  emitPath:false
                }"
              ></el-cascader>

              <div class="el-form-item__error" id="selectArea"></div>
            </el-form-item>
            <el-form-item prop="addressDetail"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.address'),
                  tips: $t('reg.pleaseAddress'),
                })">
              <el-input v-model="ruleForm.addressDetail" size="mini" :placeholder="$t('reg.pleaseAddress') + $t('reg.address')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.contacts')" prop="contacts"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.contacts'),
                  tips: $t('reg.pleaseFillin'),
                })">
              <el-input v-model="ruleForm.contacts" size="mini" :placeholder="$t('reg.pleaseFillin') + $t('reg.contacts')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.contactUs')" prop="phone"
            :rules="
                $validate({
                  noNull: true,
                  type: 'mobile',
                  label: $t('reg.pleaseMobile'),
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input v-model="ruleForm.phone" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.pleaseMobile')" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.email')" prop="email"
            :rules="
                $validate({
                  noNull: true,
                  type: 'email',
                  label: $t('reg.email'),
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input v-model="ruleForm.email" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.email')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.sms')" prop="sms">
              <el-select
                :placeholder="$t('reg.sms')"
                style="display:block;color:#ccc"
                v-model="ruleForm.sms"
                size="mini"
                @change="changeType"
              >
                <template v-for="(item, index) in contactType">
                  <el-option
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item :label="isShowType===1 ? $t('reg.code') : $t('reg.emailCheck')" prop="authCode"
              :rules="
                  $validate({
                    noNull: true,
                    label: isShowType===1 ? $t('reg.code') : $t('reg.emailCheck'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <template v-slot:error="error">
                  <div class="el-form-item__error" id="authCodeID">{{error.error}}</div>
                </template>
                <template v-if="isShowType===1">
                  <el-input v-model="ruleForm.authCode" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.code')" style="width:160px;" maxlength="6"></el-input>
                </template>
                <template v-if="isShowType===2">
                  <el-input v-model="ruleForm.authCode" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.emailCheck')" style="width:160px;" maxlength="6"></el-input>
                </template>
                <el-button type="plain" class="getCode" size="mini" @click="getCode" v-if="codeShow">{{$t('reg.getCode')}}<span id="count"></span></el-button>
                <el-button class="getCodeSend" size="mini" disabled v-if="!codeShow">{{$t('reg.getCode')}}{{count}}s</el-button>
            </el-form-item>
            <el-form-item :label="$t('reg.loginName')"
              prop="accountId"
              :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.loginName'),
                  tips: $t('reg.pleaseCreate'),
                })"
            >
              <el-input v-model="ruleForm.accountId" size="mini" :placeholder="$t('reg.pleaseCreate') + $t('reg.loginName')" @input="checkLoginName"  maxlength="32"></el-input>
              <div class="el-form-item__error" id="loginNameID"></div>
            </el-form-item>
            <el-form-item :label="$t('reg.loginPass')" prop="password"
            :rules="
              $validate({
              noNull: true,
              type: 'password',
              label: $t('reg.passWord'),
              tips: $t('base.pleaseEnter'),
            })">
              <el-input v-model="ruleForm.password" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.passWord')" :type="flag ? 'text' : 'password'" maxlength="15">
                 <i slot="suffix"
                  :class="[flag?'el-icon-minus':'el-icon-view']"
                  style="margin-top:8px;font-size:18px;"
                  autocomplete="auto"
                  @click="flag=!flag" />
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.confirmpass')" prop="confirmpass">
              <el-input v-model="ruleForm.confirmpass" :placeholder="$t('reg.pleaseConfirm') + $t('reg.passWord')" :type="flagconfirm ? 'text' : 'password'" maxlength="15">
                <i slot="suffix"
                  :class="[flagconfirm ? 'el-icon-minus': 'el-icon-view']"
                  style="margin-top:8px;font-size:18px;"
                  autocomplete="auto"
                  @click="flagconfirm=!flagconfirm" />

              </el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.manageName')" prop="manageName"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.manageName'),
                  tips: $t('reg.pleaseFillin'),
                })">
              <el-input v-model="ruleForm.manageName" size="mini" :placeholder="$t('reg.pleaseFillin') + $t('reg.manageName')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.billingtype')" prop="invoiceType">
              <el-select
                v-model="ruleForm.invoiceType"
                :placeholder="$t('reg.billingtype')"
                style="display:block;"
                size="mini"
              >
                <template v-for="(item, index) in invoiceTypeList">
                  <el-option
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('reg.invoicingname')"  prop="openAccountBankNameForInvoice"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.invoicingname'),
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input v-model="ruleForm.openAccountBankNameForInvoice" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.invoicingname')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.account')" prop="openAccountBankAccountForInvoice"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.account'),
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input v-model="ruleForm.openAccountBankAccountForInvoice" :placeholder="$t('base.pleaseEnter') + $t('reg.account')"></el-input>
            </el-form-item>
            <el-form-item v-if="ruleForm.invoiceType === 'VAT'" :label="$t('companyManage.openAccountTelNumForInvoice')" prop="openAccountTelNumForInvoice"
                          :rules="
                $validate({
                  noNull: true,
                  label: $t('companyManage.openAccountTelNumForInvoice'),
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input v-model="ruleForm.openAccountTelNumForInvoice" :placeholder="$t('base.pleaseEnter') + $t('companyManage.openAccountTelNumForInvoice')"></el-input>
            </el-form-item>
            <el-form-item v-if="ruleForm.invoiceType === 'VAT'" :label="$t('companyManage.openAccountBankAddressAddressForInvoice')" prop="openAccountTelNumForInvoice"
                          :rules="
                $validate({
                  noNull: true,
                  label: $t('companyManage.openAccountBankAddressAddressForInvoice'),
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input v-model="ruleForm.openAccountBankAddressAddressForInvoice" :placeholder="$t('base.pleaseEnter') + $t('companyManage.openAccountBankAddressAddressForInvoice')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.businesslicense')" prop="businessLicenseFileId"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.businesslicense'),
                  tips: $t('base.pleaseEnter'),
                })"
                class="followboard"
                >
                <el-upload
                  ref="uploadHeadPic"
                  action="#"
                  :http-request="businessUploadFileLoad"
                  :show-file-list="false"
                  accept="image/*"
                >
                <div class="uploadButton">
                  <template v-if="businessLicenseFile">
                    <img :src="businessLicenseFile" class="avatar" >
                  </template>
                  <template v-else>
                    <i class="yunupload"><img src="../../assets/yunUpload.png" /></i>
                  </template>
                </div>
              </el-upload>
              <div class="mobanTxt red">{{$t('reg.licenseDown')}}</div>
            </el-form-item>
            <el-form-item :label="$t('reg.authorizationUpload')" prop="authorizationFileId"
              :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.letterofauthorization'),
                  tips: $t('base.pleaseEnter'),
                })"
                class="followboard2">
              <el-upload
                ref="uploadHeadPic"
                action="#"
                :http-request="authorUploadFileLoad"
                :show-file-list="false"
                :before-upload="beforeUpload"
                accept=".pdf"
              >
              <el-button
                v-loading="pdfLoading"
                element-loading-background="rgba(0, 0, 0, 0.4)"
                icon="el-icon-plus"
                size="mini"
              >
                {{ $t('base.uploadFile') }}
              </el-button>
             </el-upload>
             <div style="margin-top: 10px; cursor: pointer;" @click="getAuthorizationPdf(filePic)">{{originalName}}</div>
                <div class="mobanTxt blue" @click="getAuthorization">{{$t('reg.authorizationDown')}}</div>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="4" class="twoButRight">
          <el-button type="primary" @click="getRegOne" class="downButtom buttonFoot">{{$t('reg.next')}}</el-button>
        </el-col>
      </el-row>
    </el-form>
    </template>
    <template v-if="stepTwo">
      <el-form ref="dataFormTwo" :model="ruleForm"  label-width="140px" >
        <el-row style="padding-top: 60px">
            <el-col :span="10" :offset="7" class="twoTop">{{ $t('reg.stepTitle') }}</el-col>
            <el-col :span="24">
              <div class="checkboxgroup">
                <el-checkbox name="applyName" v-model="ruleForm.applyTmva" :label="$t('reg.remoteGuidanceProductModule')"></el-checkbox>
                <el-checkbox name="applyName" v-model="ruleForm.applyXrWork" :label="$t('reg.workflowProductModule')"></el-checkbox>
                <el-checkbox name="applyName" :label="$t('reg.AIProductModule')" v-model="ruleForm.applyAiStation"></el-checkbox>
                <div class="el-form-item__error" id="applyNameID"></div>
              </div>
            </el-col>
        </el-row>
        <el-row class="twoBut">
          <el-col :span="24" class="twoButRight">
              <el-button type="plain" @click="getRegTwoUp" class="upButtom">{{ $t('reg.previous') }}</el-button>
              <el-button type="primary" @click="getRegTwoDown" class="downButtom">{{ $t('reg.next') }}</el-button>
            </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-if="stepThree">
      <el-form ref="dataFormThree" :model="ruleForm" label-width="140px" >
        <el-row style="padding-top: 120px">
          <el-col :span="8" :offset="6" >
            <el-form-item :label="$t('reg.serviceproviderName')" prop="serviceproviderName">
                  <el-autocomplete
                   ref="autocomplete"
                    v-model="ruleForm.serviceproviderName"
                    :fetch-suggestions="findServiceName"
                    :placeholder="$t('base.pleaseEnter') + $t('reg.serviceproviderName')"
                    :trigger-on-focus="false"
                    @select="handleSelectName"
                    :debounce='0'
                    size="mini"
                    style="width:260px"
                  ></el-autocomplete>
              </el-form-item>
            </el-col>
        </el-row>
        <el-row style="padding-top: 20px">
          <el-col :span="8" :offset="7">
            <el-radio v-model="radioAgree" :label="1" class="agreeTxt" >{{ $t('reg.agreeTextLeft') }}
              <span class="agreeTitle" @click="getAadioAgree">{{ $t('reg.agreeTextCenter') }}</span>{{ $t('reg.agreeTextRight') }}
             </el-radio>
            <div class="el-form-item__error" id="agreeTxtID"></div>
          </el-col>
        </el-row>
        <el-row class="twoBut" style="padding-top: 29px">
          <el-col :span="24" class="twoButRight">
                <el-button type="plain" @click="getRegThreeUp" class="upButtom">{{ $t('reg.previous') }}</el-button>
                <el-button type="primary" @click="getRegThreeDown" class="downButtom">{{ $t('reg.Agreeandregister') }}</el-button>
            </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-if="regSuccess">
      <el-row style="padding-top: 50px">
        <el-col :span="10" :offset="9"><img src="../../assets/regSuc.png" /></el-col>
      </el-row>
      <el-row style="padding-top: 50px">
        <el-col :span="10" :offset="8">{{$t('reg.regSuccessTxt')}}</el-col>
      </el-row>
    </template>
  </div>
</div>
</template>
<script>
import Index from "./_index.js"
export default Index
</script>

<style lang="scss">
@import "./_index.scss";
</style>
