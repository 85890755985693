// 公用api
//tmva_user_agreement
export const getCurrentPermission = 'operator/getCurrentPermission' // 获取当前权限
export const singleUpload = 'file/web/singleUpload' // 文件上传
export const getWebUploadSignature = '/file/web/getWebUploadSignature' //获取阿里云上传文件签名
// socket
export const websocketOperator = '/core/websocket/operator' // 后台管理员上报心跳

/************************** core账号管理接口开始 ************************************** */
export const login = '/core/operator/login' // 登录
export const logout = '/core/operator/logout' // 退出

//首页
export const findProductUsedTimesStatistics = '/core/product/findProductUsedTimesStatistics' //查询产品使用情况

// 功能管理
export const permissionFindTree = '/permission/findTree' // 查询
export const permissionAdd = '/permission/add' // 新增
export const permissionUpdate = '/permission/update' // 修改
export const permissionDelete = '/permission/delete' // 删除
export const permissionUpdateSort = '/permission/updateSort' // 排序
export const apiFind = '/api/find' // api接口
export const apiFindTree = '/api/findTree' // api接口树
export const getPermissionEnum = '/enums/getPermissionEnum' // 查询功能枚举
// 字典管理
export const dictFindTree = '/dict/findTree' // 查询
export const dictAdd = '/dict/add' // 新增
export const dictUpdate = '/dict/update' // 修改
export const dictDelete = '/dict/delete' // 删除
// 日志管理
export const contextLogFind = '/contextLog/find' // 日志管理
export const contextLogGetDetail = '/contextLog/getDetail' // 查询日志详情
// 消息管理
export const messageFind = '/message/find' // 查询
export const messageAdd = '/message/add' // 新增
export const messageDelete = '/message/delete' // 删除
export const messageRollback = '/message/rollback' // 撤回
export const messageUpdate = '/message/update' // 撤回
// 企业管理.
export const companyFind = '/core/company/find' // 查询
export const findFromClient = '/company/findFromClient' // 查询所有用户企业
export const companyAdd = '/company/add' // 新增
export const companyUpdate = '/company/update' // 修改
export const companyDelete = '/company/delete' // 删除
export const companySetInvoice = '/company/setInvoice' // 设置发票信息
export const findMyCompany = '/core/company/findMyCompany' // 查询我的企业
export const companyUpdateByOperator = '/company/updateByOperator' // 管理员修改企业信息
export const companyImportFromExcel = '/company/importFromExcel' // 导入企业
export const companyExportCompanyCode = '/company/exportCompanyCode' // 导出企业码
// 角色管理
export const roleFind = '/role/find' // 查询
export const roleAdd = '/role/add' // 新增
export const roleUpdate = '/role/update' // 修改
export const roleDelete = '/role/delete' // 删除
export const roleSetPermission = '/role/setPermission' // 账户管理授权
export const roleSetPermissionV1 = '/v1/role/setPermission' // 远程指导授权
export const getCurrentRolePermission = '/operator/getCurrentRolePermission' // 查询角色授权权限
export const roleGetPermission = '/role/getPermission' // 获取角色的私有化功能
export const getProductEnum = '/enums/getProductEnum' // 服务集合
export const findInfo = '/v1/role/findInfo' // 查询角色详情
export const findInfoCore = '/role/findInfo' // 查询角色详情

// 部门管理
export const userAccountGroupFindTree = '/core/userAccountGroup/findTree' // 查询
export const userAccountGroupAdd = '/userAccountGroup/add' // 新增
export const userAccountGroupUpdate = '/userAccountGroup/update' // 修改
export const userAccountGroupDelete = '/userAccountGroup/delete' // 删除
export const userAccountGroupGetParentByChildId = '/userAccountGroup/getParentByChildId' // 查询父级分组
// 管理员账号
export const operatorFind = '/operator/find' // 查询
export const operatorAdd = '/operator/add' // 新增
export const operatorUpdate = '/operator/update' // 修改
export const operatorDelete = '/operator/delete' // 删除
export const operatorDisabled = '/operator/disabled' // 禁用
export const operatorRecover = '/operator/recover' // 启用
export const operatorResetPassword = '/operator/resetPassword' // 重置密码
export const operatorSetRole = '/operator/setRole' // 分配角色
export const operatorChangePassword = '/operator/changePassword' // 修改密码
export const operatorFindMe = '/operator/findMe' // 后台管理员查询自身信息
// 忘记密码
export const changePasswordForForget = '/account/changePasswordForForget' // 忘记密码修改密码
export const bindMobileForget = '/account/bindMobile' // 忘记密码绑定手机号码
export const sendCompanyAdminCode = '/account/sendCompanyAdminCode' // 向管理员手机号码发送验证码
export const sendCompanyAdminCodeNoLogin = '/account/sendCompanyAdminCodeNoLogin' // 未登陆向管理员发送验证码

// 部门管理 企业用户账号和平台用户账户
export const userAccountFind = '/userAccount/find' // 查询
export const userAccountAdd = '/userAccount/add' // 新增
export const userAccountUpdate = '/userAccount/update' // 修改
export const userAccountDelete = '/userAccount/delete' // 删除
export const userAccountDisabled = '/userAccount/disabled' // 查询
export const userAccountRecover = '/userAccount/recover' // 查询
export const userAccountResetPassword = '/userAccount/resetPassword' // 查询
export const userAccountRenew = '/userAccount/renew' // 续订
export const userAccountExportQrCode = '/account/exportQrCode' // 用户账号导出
export const userAccountLocationFind = '/userAccountLocation/find' // 查询用户账号轨迹接口
export const userAccountImportFromExcel = '/userAccount/importFromExcel' // 账号导入
export const userAccountImportFromExcelV2 = '/account/importFromExcel' // 账号导入

// 企业配置
export const companyConfigFind = '/companyConfig/find' // 查询
export const companyConfigAdd = '/companyConfig/add' // 新增
export const companyConfigUpdate = '/companyConfig/update' // 修改
export const companyConfigDelete = '/companyConfig/delete' // 删除
export const companyGetPermission = '/company/getPermission' // 获取已授权功能
export const companySetPermission = '/company/setPermission' // 给企业授权
export const permissionLoadTree = '/permission/loadTree' // 授权时查询所有私有化功能
export const getConfigByCode = '/companyConfig/getConfigByCode' // 查询企业配置
export const getCompanyConfig = '/companyConfig/getCompanyConfig' // 获取企业配置

// 企业注册
export const coreRegisterNewCompany = '/company/register/registerNewCompany' // 企业注册接口
export const findRegisterCompanyList = '/company/register/findRegisterCompanyList' // 查询注册企业列表接口
export const coreFileupload = '/file/upload' // 单文件上传接口
export const coreSendCode = '/code/sendCode' // 发送验证码接口
export const findByNameOrMark = '/company/findByNameOrMark' // 根据企业名称和代号查询企业接口
export const vagueFindByName = '/company/vagueFindByName' // 根据输入名称模糊查询企业
export const checkCode = '/code/checkCode' // 校验验证码
export const checkRepeat = '/company/checkCompanyParameters' // 校验是否可用
export const checkLoginName = '/account/checkLoginName' // 校验验证码

// 文档管理
export const findDocument = '/document/findList' // 文档查找
export const addNewDocument = '/document/addNewDocument' // 文档添加
export const deleteDocument = '/document/delete' // 文档删除
export const putAwayDocument = '/document/putAwayDocument' // 上架文档
export const soldOutDocument = '/document/soldOutDocument' // 下架文档
export const getDocumentForValue = '/document/getDocumentForValue' //  TMVA用户协议

// 阿里云OSS下载
export const getAliyunOssSTS = "/file/getAliyunOssSTS" // 获取阿里云oss的sts请求URL
// 产品服务开通
export const findCompanyProductServiceList = '/product/findCompanyProductServiceList' // 查询企业的服务开通状态
export const findProductTypeList = '/product/findProductTypeList' // 查询服务所有类型
export const updateProductService = '/product/updateProductService' // 更新企业的服务授权情况
export const findCompanyConfig = '/product/findCompanyConfig' // 查询单一企业
// 注册用户管理
export const findRegisterUserList = '/company/register/findRegisterCompanyList' //  查询用户企业列
export const updateInvoice = '/company/register/updateInvoice' //
export const updateServiceProvider = '/company/register/updateServiceProvider' // 分配服务商
export const updateNotes = '/company/register/updateNotes' //  更新跟踪备注
export const registerCompanyGoon = '/company/register/registerCompanyGoon' //  继续注册
export const registercloseRegisterCompany = '/company/register/closeRegisterCompany' //  关闭注册

// 账号密钥授权
export const secretKeyFindList = '/secretKey/findList' //  查询密钥分配
export const addNewEntity = '/secretKey/addNewEntity' //  新增密钥分配
export const batchAuthorize = '/secretKey/batchAuthorize' // 针对于工作流 - 批量授权多个版本
export const revocation = '/secretKey/revocation' //  撤销密钥分配
export const secretKeyActive = '/secretKey/active' //  激活密钥
export const userAccountFindList = '/v1/userAccount/findList' //  激活密钥
export const secretKeyRenewal = '/secretKey/renewal' //  密钥续期
export const accountRenewal = '/secretKey/account/renewal' //  账户密钥续期
export const secretKeyDisable = '/secretKey/disabled' //  禁用密钥


/*********************************账号管理接口结束****************************************** */

// 授权分配
export const serviceResourceAssignFind = '/serviceResourceAssign/find' // 查询
export const serviceResourceAssignAssign = '/serviceResourceAssign/assign' // 分配资源
export const serviceResourceAssignRollback = '/serviceResourceAssign/rollback' // 撤销
// 账单查询
export const serviceResourceRecordList = '/serviceResource/record/list' // 查询
export const serviceResourceFindByCompany = '/serviceResource/findByCompany' // 查询当前企业资源
export const secretKeyFindRecordList = '/secretKey/findRecordList' //  查询密钥分配账单
// 设备分组
export const equipmentGroupFind = '/equipmentGroup/find' // 查询
export const equipmentGroupAdd = '/equipmentGroup/add' // 新增
export const equipmentGroupUpdate = '/equipmentGroup/update' // 修改
export const equipmentGroupDelete = '/equipmentGroup/delete' // 删除
export const getEquipmentGroupById = '/equipmentGroup/getEquipmentGroupById' // 通过id获取上一层级信息
export const equipmentHasEquipment = '/equipment/hasEquipment' // 判断当前分组下是否有设备
export const equipmentGroupWebList = '/equipmentGroup/webFind' // 外部调用设备分组
// 设备管理
export const equipmentFind = '/equipment/find' // 查询
export const equipmentAdd = '/equipment/add' // 新增
export const equipmentUpdate = '/equipment/update' // 修改
export const equipmentDelete = '/equipment/delete' // 删除
export const getEquipmentById = '/equipment/getEquipmentById' // 通过psn获取父级数据
export const equipmentCopyEquipment = '/equipment/copyEquipment' // 拷贝数据
export const equipmentMoveEquipment = '/equipment/moveEquipment' // 移动数据
export const equipmentExportEquipmentCode = '/equipment/exportEquipmentCode' // 设备二维码
export const equipmentWebFind = '/equipment/webList' // 外部调用设备
// 自用设备分组
export const customEquipmentGroupFind = '/custom/equipmentGroup/find' // 查询
export const customEquipmentGroupAdd = '/custom/equipmentGroup/add' // 新增
export const customEquipmentGroupUpdate = '/custom/equipmentGroup/update' // 修改
export const customEquipmentGroupDelete = '/tmva/custom/equipmentGroup/delete' // 删除
export const customGetEquipmentGroupById = '/custom/equipmentGroup/getEquipmentGroupById' // 通过id获取上一层级信息
export const customEquipmentHasEquipment = '/custom/equipment/hasEquipment' // 判断当前分组下是否有设备
export const customEquipmentGroupWebList = '/custom/equipmentGroup/webFind' // 外部调用自用设备分组
// 自用设备管理
export const customEquipmentFind = '/custom/equipment/find' // 查询
export const customEquipmentAdd = '/custom/equipment/add' // 新增
export const customEquipmentUpdate = '/custom/equipment/update' // 修改
export const customEquipmentDelete = '/custom/equipment/delete' // 删除
export const customGetEquipmentById = '/custom/equipment/getEquipmentById' // 通过psn获取父级数据
export const customEquipmentCopyEquipment = '/custom/equipment/copyEquipment' // 拷贝数据
export const customEquipmentMoveEquipment = '/custom/equipment/moveEquipment' // 移动数据
export const customEquipmentExportEquipmentCode = '/custom/equipment/exportEquipmentCode' // 设备二维码
export const customEquipmentWebFind = '/custom/equipment/webList' // 外部调用自用设备
// 客户管理
export const customerList = '/customer/list' // 查询
export const customerAdd = '/customer/add' // 新增
export const customerUpdate = '/customer/update' // 修改
export const customerDelete = '/customer/delete' // 删除
export const customerImportCustomer = '/customer/importCustomer' // 导入
// 地址池管理
export const poolList = '/pool/list' // 查询
export const poolAdd = '/pool/add' // 新增
export const poolUpdate = '/pool/update' // 修改
export const poolDelete = '/pool/delete' // 删除
export const poolImportArSx = '/pool/importArSx' // 导入AR数显
export const poolExportArSxCode = '/pool/exportArSxCode' // 导出AR数显
export const poolImportArPool = '/pool/importArPool' // 导入AR设备地址
export const poolImportGateway = '/pool/importGateway' // 导入网关地址
export const equipmentPoolList = '/equipmentPool/list' // 查询AR码池
export const equipmentPoolDelete = '/equipmentPool/delete' // 删除AR码池

// 销售信息
export const saleList = '/sale/list' // 查询
export const saleAdd = '/sale/add' // 新增
export const saleUpdate = '/sale/update' // 修改
export const saleDelete = '/sale/delete' // 删除
export const saleEquipmentGetById = '/saleEquipment/getById' // 查询设备信息详情
export const saleExportSaleCode = '/sale/exportSaleCode' // 导出
export const saleListDelete = '/sale/listDelete' // 删除记录
// 网关管理
export const gatewayGetList = '/gateway/getList' // 查询
export const gatewayResetPassword = '/gateway/resetPassword' // 重置密码
export const gatewayExportGateway = '/gateway/exportGateway' // 导出
export const gatewayFind = '/gateway/find' // 获取网关操作日志

// 版本管理
export const versionFind = '/version/find' // 查询
export const versionAdd = '/version/add' // 新增
export const versionUpdate = '/version/update' // 修改
export const versionDelete = '/version/delete' // 删除
export const versionUserAccountAdd = '/versionUserAccount/add' // 添加用户
export const versionUserAccountFind = '/versionUserAccount/find' // 添加用户
export const versionUserAccountDelete = '/versionUserAccount/delete' // 删除用户
// 终端日志
export const clientLogFind = '/clientLog/find' // 查询

// 设备激活码
export const terminalFindActivationCode = '/terminal/findActivationCode' // 查找
export const terminalAddActivationCode = '/terminal/addActivationCode' // 新增
export const terminalChangeActivationCode = '/terminal/changeActivationCode' // 禁用
export const terminalExportActivationCode = '/terminal/exportActivationCode' // 导出
export const terminalFindFunction = '/terminal/findFunction' // 查询终端功能
// 前台查询设备信息
export const equipmentGetEquipmentByWebCode = '/qrCode/scan' // 通过二维码信息获取 设备/部件/故障信息
export const customEquipmentGetEquipmentByWebCode = '/custom/eqrCode/scan' // 通过二维码信息获取 设备/部件/故障信息
// 短信
export const sendCode = '/code/sendCode'
export const bindMobile = '/userAccount/bindMobile' // 绑定手机号码接口
export const operatorbindMobile = '/operator/bindMobile' // 管理员绑定手机号码接口
export const changePassword = '/userAccount/changePassword'  // 用户自己修改密码忘记密码重置新的密码
export const changeMobile = '/userAccount/changeMobile' // 修改手机号码接口

//权限管理
export const findAccountPermissionList = '/account/permission/find' //查询企业账户权限列表
export const changeContactsAddAble = '/account/permission/changeContactsAddAble' //修改是否可以添加联系人
export const findAccountPermission = '/account/permission/findAccountPermission' //查询账户的可见范围
export const disabledAccount = '/secretKey/disabledAccount' //账户密钥停用
export const findCompanyTreeContacts = '/userAccount/findCompanyTreeContacts' //查询企业树型用户列表
export const changeContactsVisible = '/account/permission/changeContactsVisible' //修改可见范围
export const findCompanyPermissionList = '/company/permission/find' //查询企业权限列表
export const updateCompanyPermission = '/company/permission/updatePermission' //修改企业权限
export const updateVisible = '/account/permission/updateVisible' //更新账户的可见范围
export const aiStationUpdateVisible = '/account/permission/updateAccountPermission' //更新账户的可见范围

//注册用户
export const checkMobile = '/userAccount/register/checkMobile' // 注册账号-校验手机号码
export const userRegister = '/userAccount/register' // 注册账号
export const  applyJoinCompany = '/company/applyJoinCompany' // 申请加入企业
export const userLogin = '/userAccount/login' // 用户登录
export const findApplyJoinList = '/company/findApplyJoinList' // 查询企业申请加入列表
export const createCompanyInviteCode = '/company/createCompanyInviteCode' // 创建企业邀请码
export const findCompanyInviteCode = '/company/findCompanyInviteCode' // 查询企业邀请码
export const dealCompanyJoinApply = '/company/dealCompanyJoinApply' // 处理加入申请




//
export const findExpiryTime = '/core/licence/server/findExpiryTime' // 查询服务许可证过期时间
export const serverUpload = '/core/licence/server/upload' // 上传服务许可证文件
//蓝牙标签管理
export const bleLabelList = '/label/ble/findList' //列表
export const bleLabelStore = '/label/ble/add' //增减
export const bleLabelUpdate = '/label/ble/change' //修改
export const bleLabelDelete = '/label/ble/delete' //删除
export const bleLabelDownloadTemplate = '/label/ble/downloadWorkFlowSimple' //下载模板

//RFID标签管理
export const rfidLabelList = '/label/rfid/findList' //列表
export const rfidLabelStore = '/label/rfid/add' //增减
export const rfidLabelUpdate = '/label/rfid/change' //修改
export const rfidLabelDelete = '/label/rfid/delete' //删除
export const rfidLabelDownloadTemplate = '/label/rfid/downloadWorkFlowSimple' //下载模板


export const parseExcel = '/file/parseExcel' //上传模板


//接口管理
export const apiAdd = '/api/add' // 添加
export const apiList = '/api/findList' // 列表
export const apiUpdate = '/api/update' // 修改
export const apiDel = '/api/delete' // 删除


//数据管理
export const fieldAdd = '/api/field/add' // 添加
export const fieldUpdate = '/api/field/update' // 添加
export const findJsonTree = '/api/findJsonTree' // 添加
export const fieldFindList = '/api/field/findList' // 添加
export const fieldDel = '/api/field/delete' // 添加


//传感器设备
export const sensorAdd = '/sensorDevice/add' //添加
export const sensorFindList = '/sensorDevice/findList' //查询
export const sensorEdit = '/sensorDevice/update' //编辑
export const sensorDel = '/sensorDevice/delete' //删除

//模板管理
export const templateAdd = '/workFlowStep/template/addTemplateStep' //添加
export const templateFindList = '/workFlowStep/template/find' //查询
export const templateEdit = '/workFlowStep/template/updateTemplateStep' //编辑
export const templateDel = '/workFlowStep/template/delTemplateStep' //删除
export const templateType = '/workFlowStep/template/findTemplateStepDataType' //查询模板参数类型列表
export const templateExport = '/workFlowStep/template/exportTemplateStep' // 导出
export const templateImport = '/workFlowStep/template/importTemplateStep' // 导入
export const findCompanyAssignedTemplate = '/workFlowStep/template/find' // 查询企业模板
export const assignTemplate = '/workFlowStep/template/assignCompanyWorkFlowTemplateStep' // 模板分配

//BI大屏管理
export const biAdd = '/BI/manage/add' //添加
export const biFindList = '/BI/manage/find' //查询
export const biEdit = '/BI/manage/update' //编辑
export const biDel = '/BI/manage/delete' //删除
export const biDisable = '/BI/manage/disable' //禁用
export const biRecover = '/BI/manage/recover' //恢复

// 许可证管理
export const findLicenceExpiryTime = '/core/licence/server/findExpiryTime' //查询服务许可证过期时间
export const updateLicence = '/core/licence/server/upload' //上传服务许可证文件

export const  arithmeticFindList = '/recognitionArithmetic/findList' // 算法列表
export const  arithmeticAdd = '/recognitionArithmetic/add' // 添加算法
export const  arithmeticUpdate = '/recognitionArithmetic/update' // 修改算法
export const  arithmeticDelete = '/recognitionArithmetic/delete' // 删除算法

// 数据中心管理
export const dataCenterFindList  = '/api/field/findList' // 获取api

//工作流版本
export const  findProductVersionType = '/workFlow/product/version/findProductVersionType' // 查询版本
export const  findCompanyProductVersion = '/workFlow/product/version/findCompanyProductVersion' // 查询企业版本
export const  updateCompanyProductVersion = '/workFlow/product/version/updateCompanyProductVersion' // 版本更新企业版本

export const sqlAdjust = '/core/sql/adjust' //数据库优化

//

export const downloadRegisterFile = '/core/licence/server/downloadRegisterFile' // 下载注册信息
export const downloadTransferFile = '/core/licence/server/downloadTransferFile' // 下载迁移文件


// 识别算法
export const findRecognitionAlgorithmTypes = '/recognitionArithmetic/findRecognitionAlgorithmTypes' // 识别算法类型
export const switchExternalAiApiData = '/recognitionArithmetic/external/switchExternalAiApiData' // 将外部算法接口的返回数据转换成识别类型实体类结构
export const findExternalAiApiData = '/recognitionArithmetic/external/findExternalAiApiData' // 查看当前外部算法已经配置的标签

export const arithmeticPlatformAdd = '/arithmeticPlatform/add' // 算法平台新增
export const arithmeticPlatformUpdate = '/arithmeticPlatform/update' // 算法平台更新
export const arithmeticPlatformDelete = '/arithmeticPlatform/delete' // 算法平台删除
export const arithmeticPlatformFindArithmeticPlatforms = '/arithmeticPlatform/findArithmeticPlatforms' // 算法平台列表
export const findArithmeticPlatformRecognitionList = '/arithmeticPlatform/findArithmeticPlatformRecognitionList' // 查询算法平台算法列表
export const addArithmeticApi = '/recognitionArithmetic/addArithmeticApi' // 新增算法api

//OEM管理
export const oemConfigFindList = '/core/companyOemConfig/findList'
export const oemConfigRecover = '/core/companyOemConfig/recover'
export const oemConfigAdd = '/core/companyOemConfig/add'
export const oemConfigChange = '/core/companyOemConfig/change'
export const oemConfigDel = '/core/companyOemConfig/del'



