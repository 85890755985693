// 算法类型枚举
export default {
    SUBSTATION_SWITCH: '变电站开关',
    ORDINARY_POINTER_PRESSURE_GAUGE: '普通指针压力表',
    SHOCK_RESISTANT_POINTER_PRESSURE_GAUGE: '耐震指针压力表',
    ORDINARY_DIGITAL_PRESSURE_GAUGE: '普通数显压力表',
    API: '外部API',
    AI_STATION: 'AI智算平台',
    EXTERNAL_AI_IMG: '图片识别',
    EXTERNAL_AI_VIDEO: '视频流识别'
}
