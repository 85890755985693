var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"pageBox"},[_c('el-row',{staticClass:"pageTop"},[_c('el-col',{attrs:{"span":2}},[_c('el-select',{staticStyle:{"width":"100px"},attrs:{"placeholder":"请选择","size":"mini"},on:{"change":_vm.changeLang},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}},_vm._l((_vm.langData),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),(_vm.stepTop)?_c('el-col',{attrs:{"span":12,"offset":4}},[_c('div',{staticClass:"stepBox"},[_c('div',{staticClass:"item",class:[_vm.stepNum < 4 ? 'active' : 'grey']},[_c('span',[_vm._v("1")]),_vm._v(_vm._s(_vm.$t('accountReg.verifyMobile')))]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../../assets/jiantou.png")}})]),_c('div',{staticClass:"item",class:[_vm.stepNum > 1 || _vm.stepNum === 2 ? 'active' : 'grey']},[_c('span',[_vm._v("2")]),_vm._v(_vm._s(_vm.$t('accountReg.affiliateSignup')))]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../../assets/jiantou.png")}})]),_c('div',{staticClass:"item",class:[_vm.stepNum === 3 ? 'active' : 'grey']},[_c('span',[_vm._v("3")]),_vm._v(_vm._s(_vm.$t('accountReg.joinCompany')))])])]):_vm._e(),_c('el-col',{attrs:{"span":1,"offset":3}})],1),(_vm.stepOne)?[_c('el-form',{ref:"dataForm",attrs:{"model":_vm.ruleForm,"label-width":"140px"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":10,"offset":7}},[_c('div',{staticClass:"formBox"},[_c('el-form-item',{attrs:{"label":_vm.$t('accountReg.mobile'),"prop":"phone","rules":_vm.$validate({
                    noNull: true,
                    type: 'mobile',
                    label: _vm.$t('accountReg.mobile'),
                    tips: _vm.$t('base.pleaseEnter'),
                  })}},[_c('el-input',{attrs:{"size":"mini","placeholder":_vm.$t('base.pleaseEnter') + _vm.$t('accountReg.pleaseMobile'),"maxlength":"11"},model:{value:(_vm.ruleForm.phone),callback:function ($$v) {_vm.$set(_vm.ruleForm, "phone", $$v)},expression:"ruleForm.phone"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('accountReg.code'),"prop":"authCode","rules":_vm.$validate({
                      noNull: true,
                      label: _vm.$t('accountReg.code'),
                      tips: _vm.$t('base.pleaseEnter'),
                    })}},[_c('el-input',{staticStyle:{"width":"160px"},attrs:{"size":"mini","placeholder":_vm.$t('base.pleaseEnter') + _vm.$t('reg.code'),"maxlength":"6"},model:{value:(_vm.ruleForm.authCode),callback:function ($$v) {_vm.$set(_vm.ruleForm, "authCode", $$v)},expression:"ruleForm.authCode"}}),(_vm.codeShow)?_c('el-button',{staticClass:"getCode",attrs:{"type":"plain","size":"mini"},on:{"click":_vm.getCode}},[_vm._v(_vm._s(_vm.$t('reg.getCode'))),_c('span',{attrs:{"id":"count"}})]):_vm._e(),(!_vm.codeShow)?_c('el-button',{staticClass:"getCodeSend",attrs:{"size":"mini","disabled":""}},[_vm._v(_vm._s(_vm.$t('reg.getCode'))+_vm._s(_vm.count)+"s")]):_vm._e()],1),_c('el-form-item',{attrs:{"rules":_vm.$validate({
                      noNull: true,
                    }),"prop":"isAgree"}},[_c('el-checkbox',{model:{value:(_vm.ruleForm.isAgree),callback:function ($$v) {_vm.$set(_vm.ruleForm, "isAgree", $$v)},expression:"ruleForm.isAgree"}}),_c('span',{staticClass:"agreeText"},[_vm._v(" "+_vm._s(_vm.$t('accountReg.isAgree'))+" "),_c('span',{staticClass:"agreeTitle",on:{"click":function($event){return _vm.getAgree('service_agreement')}}},[_vm._v("《"+_vm._s(_vm.$t('accountReg.serviceAgreement'))+"》")]),_c('span',{staticClass:"agreeTitle",on:{"click":function($event){return _vm.getAgree('privacy_policy')}}},[_vm._v("《"+_vm._s(_vm.$t('accountReg.privacyPolicy'))+"》")]),_c('div',{staticClass:"el-form-item__error",attrs:{"id":"agreeTxtID"}})])],1)],1)]),_c('el-col',{staticClass:"twoButRight",attrs:{"span":4}},[_c('el-button',{staticClass:"downButtom buttonFoot",attrs:{"type":"primary"},on:{"click":_vm.getRegOne}},[_vm._v(_vm._s(_vm.$t('accountReg.next')))])],1)],1)],1)]:_vm._e(),(_vm.stepTwo)?[_c('el-form',{ref:"dataFormTwo",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"140px"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":10,"offset":7}},[_c('div',{staticClass:"formBox"},[_c('el-form-item',{attrs:{"label":_vm.$t('accountReg.loginName'),"prop":"loginName","rules":_vm.$validate({
                    noNull: true,
                    min: 5,
                    max: 10,
                    label: _vm.$t('accountReg.loginName'),
                    tips: _vm.$t('base.pleaseEnter'),
                  })}},[_c('el-input',{attrs:{"size":"mini","placeholder":_vm.$t('base.pleaseEnter') + _vm.$t('accountReg.loginName')},model:{value:(_vm.ruleForm.loginName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "loginName", $$v)},expression:"ruleForm.loginName"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('accountReg.name'),"prop":"name","rules":_vm.$validate({
                    noNull: true,
                    min: 2,
                    max: 10,
                    label: _vm.$t('accountReg.name'),
                    tips: _vm.$t('base.pleaseEnter'),
                  })}},[_c('el-input',{attrs:{"size":"mini","placeholder":_vm.$t('base.pleaseEnter') + _vm.$t('accountReg.name')},model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('accountReg.loginPass'),"prop":"password","rules":_vm.$validate({
              noNull: true,
              type: 'password',
              label: _vm.$t('reg.passWord'),
              tips: _vm.$t('base.pleaseEnter'),
            })}},[_c('el-input',{attrs:{"size":"mini","placeholder":_vm.$t('base.pleaseEnter') + _vm.$t('reg.passWord'),"type":_vm.flag ? 'text' : 'password',"maxlength":"15"},model:{value:(_vm.ruleForm.password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "password", $$v)},expression:"ruleForm.password"}},[_c('i',{class:[_vm.flag?'el-icon-minus':'el-icon-view'],staticStyle:{"margin-top":"8px","font-size":"18px"},attrs:{"slot":"suffix","autocomplete":"auto"},on:{"click":function($event){_vm.flag=!_vm.flag}},slot:"suffix"})])],1),_c('el-form-item',{attrs:{"label":_vm.$t('accountReg.confirmpass'),"prop":"confirmpass"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('accountReg.pleaseConfirm') + _vm.$t('accountReg.passWord'),"type":_vm.flagconfirm ? 'text' : 'password',"maxlength":"15"},model:{value:(_vm.ruleForm.confirmpass),callback:function ($$v) {_vm.$set(_vm.ruleForm, "confirmpass", $$v)},expression:"ruleForm.confirmpass"}},[_c('i',{class:[_vm.flagconfirm ? 'el-icon-minus': 'el-icon-view'],staticStyle:{"margin-top":"8px","font-size":"18px"},attrs:{"slot":"suffix","autocomplete":"auto"},on:{"click":function($event){_vm.flagconfirm=!_vm.flagconfirm}},slot:"suffix"})])],1)],1)]),_c('el-col',{staticClass:"twoButRight",attrs:{"span":4}},[_c('el-button',{staticClass:"downButtom buttonFoot",attrs:{"type":"primary"},on:{"click":_vm.goReg}},[_vm._v(_vm._s(_vm.$t('accountReg.reg')))])],1)],1)],1)]:_vm._e(),(_vm.stepThree)?[_c('el-form',{ref:"dataFormThree",attrs:{"model":_vm.ruleForm,"label-width":"140px"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":10,"offset":7}},[_c('div',{staticClass:"formBox"},[_c('el-form-item',{attrs:{"label":_vm.$t('accountReg.company'),"prop":"companyName","rules":_vm.$validate({
                    noNull: true,
                    label: _vm.$t('accountReg.companyUid'),
                    tips: _vm.$t('base.pleaseSelect1'),
                  })}},[_c('el-autocomplete',{ref:"autocomplete",staticStyle:{"width":"260px"},attrs:{"fetch-suggestions":_vm.findServiceName,"placeholder":_vm.$t('base.pleaseEnter') + _vm.$t('accountReg.companyName'),"trigger-on-focus":false,"debounce":0,"size":"mini"},on:{"select":_vm.handleSelectName},model:{value:(_vm.ruleForm.companyName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "companyName", $$v)},expression:"ruleForm.companyName"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('accountReg.inviteCode'),"prop":"inviteCode","rules":_vm.$validate({
                    noNull: true,
                    label: _vm.$t('accountReg.inviteCode'),
                    tips: _vm.$t('base.pleaseEnter'),
                  })}},[_c('el-input',{attrs:{"size":"mini","placeholder":_vm.$t('base.pleaseEnter') + _vm.$t('accountReg.inviteCode')},model:{value:(_vm.ruleForm.inviteCode),callback:function ($$v) {_vm.$set(_vm.ruleForm, "inviteCode", $$v)},expression:"ruleForm.inviteCode"}})],1)],1)]),_c('el-col',{staticClass:"twoButRight",attrs:{"span":4}},[_c('el-button',{staticClass:"downButtom buttonFoot",attrs:{"type":"primary"},on:{"click":_vm.joinCompany}},[_vm._v(_vm._s(_vm.$t('accountReg.confirm')))])],1)],1)],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }