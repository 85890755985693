// 页面配置
import Home from '@/layout/home'
import Login from '@/layout/login'
import Main from '@/layout/main'
import SecretKey from '@/layout/secretKey'
import Reg from '@/layout/reg'
import accountReg from '@/layout/accountReg'
import Forgot from '@/layout/forgot'
import PcEquipmentManage from '@/views/pcEquipmentManage/index'
import InviteEquipment from '@/views/inviteEquipment/index'
import AdminAccount from '@/views/accountManage/adminAccount/index'

export default [
  {
    path: '/core',
    component: Home,
    children: [{ path: '/core', name: 'core', component: () => import('@/views/index') }],
  },
  {
    path: '/tmva',
    component: Home,
    children: [{ path: '/tmva', name: 'tmva', component: () => import('@/views/index') }],
  },
  {
    path: '/workFlow',
    component: Home,
    children: [{ path: '/workFlow', name: 'workFlow', component: () => import('@/views/index') }],
  },
  {
    path: '/tmvaAiStation',
    component: Home,
    children: [{ path: '/tmvaAiStation', name: 'tmvaAiStation', component: () => import('@/views/index') }],
  },
  {
    path: '/idc',
    component: Home,
    children: [{ path: '/idc', name: 'idc', component: () => import('@/views/index') }],
  },
  {
    path: '/device',
    component: Home,
    children: [{ path: '/device', name: 'device', component: () => import('@/views/index') }],
  },
  {
    path: '/main',
    component: Main,
  },
  {
    path: '/secret-key',
    component: SecretKey,
  },
  {
    path: '/reg',
    name:'reg',
    component: Reg,
  },
  {
    path: '/account-reg',
    name:'accountReg',
    component: accountReg,
  },
  {
    path: '/forgot',
    name:'forgot',
    component: Forgot,
  },
  { path: '/login', name: 'login', component: Login },
  { path: '/pcEquipmentManage', name: 'pcEquipmentManage', component: PcEquipmentManage },
  { path: '/inviteEquipment', name: 'inviteEquipment', component: InviteEquipment },
  { path: '/adminAccount', name: 'adminAccount', component: AdminAccount },
  { path: '/', redirect: '/main' },
]
