// 省市区数据来源
import 'vue-area-linkage/dist/index.css'
import { pca, pcaa } from 'area-data'
import { singleUpload } from '@/api'
import fakeClickOutSide from '../../utils/fackClickOutSide'

export default {
  mixins: [fakeClickOutSide],
  data() {
    let checkPwd = (rule,value,callback) => {
      if(value.trim().length==0){
        callback(new Error(`${this.$t('reg.pleaseinputpass')}`))
      }else if(value !=this.ruleForm.password){
        callback(new Error(`${this.$t('reg.confirmCheck')}`))
      }else{
        callback()
      }
    }
    let checkIndustry = (rule,value,callback) => {
      if(value.length==0){
        callback(new Error("请输入所属行业"))
      }else{
        callback()
      }
    }
    let checkAddressData = (rule,value,callback) => {
      if(value.length == 0){
        document.getElementById('selectArea').textContent = '请输入选择地址'
        callback()
      }else{
        document.getElementById('selectArea').textContent = ''
        callback()
      }
    }

    return {
      langData: [
      {
        value: 'zhCN',
        label: this.$t('base.zhCN')
      },
      // {
      //   value: 'en',
      //   label: this.$t('base.en')
      // },
      // {
      //   value: 'ja',
      //   label: this.$t('base.ja')
      // }
      ],
      lang: this.$util.getLocalStorage('lang') || 'zhCN',
      ruleForm:{
        companyName:'',
        creditCodeForInvoice:'',
        industryId:'',
        customIndustry: '',
        businessScope:'',
        addressId:'',
        addressDetail:'',
        contacts:'',
        phone:'',
        email:'',
        sms: 1,
        authCode:'',
        accountId:'',
        password:'',
        confirmpass:'',
        invoiceType:'GENERAL',
        openAccountBankNameForInvoice: null,
        openAccountBankAccountForInvoice: null,
        openAccountTelNumForInvoice: null,
        openAccountBankAddressAddressForInvoice: null,
        businessLicenseFileId:'',
        authorizationFileId:'',
        serviceproviderName:'',
        serviceProviderId:'',
        applyTmva:'',
        applyXrWork: '',
        applyAiStation: '',
        industryList:[],
        addressData:[],
        manageName:'',
      },
      industryLabelText:"",
      isShowType: 1,
      bindMobileShow:false,
      pca: pca,
      pcaa: pcaa,
      stepTop:true,
      stepOne: true,
      stepTwo: false,
      stepThree:false,
      stepNum:1,
      regSuccess:false,
      showImage: false,
      showLicense: false,
      formReg:true,
      licenseImg: '',
      authorizationImg: '',
      invoiceTypeList: this.$dict.invoiceType(),
      authorizationFile:'',
      businessLicenseFile: '',
      serviceListData:[],
      rules: {
        confirmpass: [{validator: checkPwd,required: true,trigger: "blur",}],
        industryId: [{required: true, validator: checkIndustry,trigger: ["blur", 'change'], type: 'array'}],
        addressId: [{required: true, validator: checkAddressData,trigger: ["blur", 'change'], type: 'array'}],
      },
      action: singleUpload,
      loading: false,
      radioAgree: 0,
      isShowSelect: false,
      count: '',
      timer:null ,
      codeShow: true,
      loginNameExist:false,
      authCodeExist:false,
      listServerData:[],
      pdfLoading: false,
      originalName: '',
      flag: false,
      flagconfirm: false,
      filePic:{},
      // 认证方式
      contactType:[
        {
          value: 1,
          label: this.$t('reg.code')
        },
        {
          value: 2,
          label: this.$t('reg.emailCheck')
        },
      ],
    }
  },
  watch: {
    'ruleForm.phone'(val) {
      this.ruleForm.phone = val.replace(/[^0-9.]/g,'')
    },
    'ruleForm.openAccountBankAccountForInvoice'(val) {
      this.ruleForm.openAccountBankAccountForInvoice = val.replace(/[^0-9.]/g,'')
    }
  },
  mounted() {
   this.setDefaultValue()
  },
  methods: {
    setDefaultValue() {
      this.$nextTick(() => {
        const language = this.$util.getLocalStorage('lang')
        //默认值
        this.dictFindTree('COMPANY_INDUSTRY', language)
        this.dictFindTree('ADDRESS', language)
      })
    },

    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        console.log(data)
        if (dictType === 'COMPANY_INDUSTRY') {
          data.push({
            id: null,
            value: '其他',
          })
        }
        if (dictType === 'COMPANY_INDUSTRY') {
          this.ruleForm.industryList = data || []
        } else {
          this.ruleForm.addressData = data || []
        }
      })
    },
    changeLang(lang) {
      this.lang = lang
      this.$i18n.locale = lang
      this.$util.setLocalStorage('lang', lang)
    },
    checkLoginName(val){
      console.log(val)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.checkLoginName,
        data:{
          loginName: val
        }
      }).then((data) => {
        this.loginNameExist = false
        document.getElementById('loginNameID').textContent = ''
        console.log(data)
      }).catch(err =>{
        this.loginNameExist = true
        document.getElementById('loginNameID').textContent = this.$t(`serverCode.${err.msg}`)
        return false
      })
    },

    getCode() {
      if(!this.ruleForm.phone) {
        this.$element.showMsg(this.$t('reg.mobileBind'), 'warning')
        return
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreSendCode,
        data:{
          mobile: this.ruleForm.phone,
          countryCode:''
        }
      }).then((data) => {
        this.ruleForm.authCodeSeqNo = data
        console.log(data)
      })
      const TIME_COUNT = 60  //倒计时60秒
      if(!this.timer) {
        this.count = TIME_COUNT
        this.codeShow = false
        this.timer = setInterval(() => {
          if(this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.codeShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    checkCode(){
      return this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.checkCode,
        data:{
          mobile: this.ruleForm.phone,
          seqNo:this.ruleForm.authCodeSeqNo,
          code:this.ruleForm.authCode
        }
      })
    },
    changeType(val) {
      this.isShowType = val
    },
    getBack() {
      this.$router.push({ path: '/login'})
    },
    changeIndustry(val) {
      console.log(val)
      this.$set(this.ruleForm, 'industryId', val)
    },
    changeAdress(val){
      console.log(val)
      this.$set(this.ruleForm, 'addressId', val)
    },
    // 第一步
    getRegOne() {
      // this.checkCode()
      // setTimeout(() => {
      //   this.$refs.dataForm.validate(valid => {
      //     console.log('authCodeExist', this.authCodeExist)
      //     if(this.authCodeExist){
      //       document.getElementById('authCodeID').textContent = this.$t(`serverCode.SEND_MESSAGE_AUTH_CODE_000002`)
      //       return false
      //     }
      //     if(this.loginNameExist){
      //       document.getElementById('loginNameID').textContent = this.$t(`serverCode.ACCOUNT_000016`)
      //       return false
      //     }
      //     if (valid) {
      //         this.stepOne = false
      //         this.stepTwo = true
      //         this.stepThree = false
      //         this.stepNum = 2
      //         console.log(this.ruleForm)
      //     } else {
      //       return false
      //     }
      //   })
      // }, 500)
      this.$refs.dataForm.validate((valid) => {
        if(valid) {
          this.checkCode().then((data) => {
            let fields = {
              companyName: this.ruleForm.companyName,
              creditCodeForInvoice: this.ruleForm.creditCodeForInvoice,
              phone: this.ruleForm.phone,
              email: this.ruleForm.email,
              accountId: this.ruleForm.accountId,
              openAccountBankAccountForInvoice: this.ruleForm.openAccountBankAccountForInvoice,
            }
            this.checkRepeat(fields).then(res => {
              this.stepOne = false
              this.stepTwo = true
              this.stepThree = false
              this.stepNum = 2
            }).catch(err => {

            })
          }).catch((err) => {
            console.log(err)
            this.$refs['dataForm'].fields[10].validateMessage = this.$t(`serverCode.${err.msg}`)
            this.$refs['dataForm'].fields[10].validateState = 'error'
          })
        } else {
          return false
        }
      })
    },
    checkRepeat(fields) {
      console.log(this.$util.getLocalStorage('apiprefix'))
      return this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.checkRepeat,
        data: fields
      })
    },
    // 上一步
    getRegTwoUp() {
      this.stepOne = true
      this.stepTwo = false
      this.stepThree = false
      this.stepNum = 1
      console.log(this.ruleForm)
      const {authorization,license } = this.ruleForm

      console.log(license, authorization)
      if(license) {
        this.showLicense = true
        this.licenseImg = license
      }
      if(authorization) {
        this.showLicense = true
        this.authorizationImg = authorization
      }
    },
    getRegTwoDown() {
      if(this.$util.checkboxs("applyName") == false) {
       document.getElementById('applyNameID').textContent = this.$t('reg.selectTmva')
       return false
      }
      document.getElementById('applyNameID').textContent = ''
      this.stepOne = false
      this.stepTwo = false
      this.stepThree = true
      this.stepNum = 3
      console.log(this.ruleForm)
    },
    getRegThreeUp() {
      this.stepOne = false
      this.stepTwo = true
      this.stepThree = false
      this.stepNum = 2
      console.log(this.ruleForm)
    },
    // 同意并注册
    getRegThreeDown() {
      if(this.radioAgree === 0) {
        document.getElementById('agreeTxtID').textContent = this.$t('reg.agreeTxtID')
        return false
      }
      this.$refs.dataFormThree.validate(valid => {
        if (valid) {
          console.log(this.ruleForm)
          const { addressData } = this.ruleForm
          console.log(addressData[0].value)
          const ruleForm = this.$util.copyData(this.ruleForm)
          if(ruleForm.applyTmva){
            ruleForm.applyTmva = "YES"
          }else{
            ruleForm.applyTmva = "NO"
          }
          if(ruleForm.applyXrWork){
            ruleForm.applyXrWork = "YES"
          }else{
            ruleForm.applyXrWork = "NO"
          }
          if(ruleForm.applyAiStation){
            ruleForm.applyAiStation = "YES"
          }else{
            ruleForm.applyAiStation = "NO"
          }
          ruleForm.invoice = {
            invoiceType: ruleForm.invoiceType,
            companyNameForInvoice: ruleForm.companyName,
            creditCodeForInvoice: ruleForm.creditCodeForInvoice,
            openAccountBankNameForInvoice: ruleForm.openAccountBankNameForInvoice,
            openAccountBankAccountForInvoice: ruleForm.openAccountBankAccountForInvoice,
            openAccountTelNumForInvoice: ruleForm.openAccountTelNumForInvoice,
            openAccountBankAddressAddressForInvoice: ruleForm.openAccountBankAddressAddressForInvoice,
          }
          delete ruleForm.addressData
          delete ruleForm.industryList
          console.log(ruleForm)
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreRegisterNewCompany,
            data: ruleForm
          }).then((data) => {
            console.log(data)
            this.regSuccess = true
            this.stepThree = false
            this.stepTop = false
          })

        } else {
          return false
        }
      })
    },
    getAadioAgree() {
      document.getElementById('agreeTxtID').textContent = ''
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getDocumentForValue,
        data:{
          value: 'tmva_user_agreement',
        }
      }).then((data) => {
        const { fileEntity } = data
        const { originalName, objectName }  = fileEntity
        this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getAliyunOssSTS,
          }).then((res) => {
            const OSS = require('ali-oss')
            const client = new OSS({
              region: res.region,
              accessKeyId: res.accessKeyId,
              accessKeySecret: res.accessKeySecret,
              stsToken: res.securityToken,
              bucket: res.bucketName,
            })
            const filename = originalName
            const response = {
              'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
            }
            const urlDowm = client.signatureUrl(objectName, { response })
            const a = document.createElement('a')
            // a.target = '_blank'
            a.href = urlDowm
            a.click()
          })
      })
    },
    getLicense(e) {
      const imgItem = this.$util.copyData(e)
      this.showLicense = true
      const {fileHost, objectName} = imgItem[0]

      this.ruleForm.license = fileHost + '/' + objectName
      console.log(this.ruleForm.license)
    },
    getAuthor(e) {
      const imgItem = this.$util.copyData(e)
      this.showImage = true
      const {fileHost, objectName} = imgItem[0]

      this.ruleForm.authorization = fileHost + '/' + objectName
      console.log(this.ruleForm.authorization)
    },
    // 服务商名称
    findServiceName(e,callback) {
      var that = this
      that.ruleForm.serviceproviderName = e
      that.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findByNameOrMark,
        data:{
          name: e,
        }
      }).then((data) => {
        console.log(data)
        this.listServerData = data
        if (this.listServerData.length == '0') {
          this.listServerData.push({
            id: '-1',
            value: '无匹配结果'
          })
          callback(this.listServerData)
        } else {
          this.listServerData = this.listServerData.map(item=>{
          return {
            value:`${item.name}`,
            id: `${item.id}`
          }
        })
         callback(this.listServerData)
        }
      }).catch((error)=>{
        console.log(error)
       })
    },
    handleSelectName(item) {
      console.log(item)
      this.ruleForm.serviceProviderId = item.id + ''
      this.ruleForm.serviceproviderName = item.value
    },
    // 服务商代码
    findServiceCode(e) {
      console.log(e)
      this.ruleForm.serviceproviderCode = e
    },
    // 授权书模板下载
    getAuthorization() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getDocumentForValue,
        data:{
          value: 'register_authorization_form',
        }
      }).then((data) => {
        console.log(data)
        const { fileEntity } = data
        const { originalName, objectName }  = fileEntity
        this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getAliyunOssSTS,
          }).then((res) => {
            const OSS = require('ali-oss')
            const client = new OSS({
              region: res.region,
              accessKeyId: res.accessKeyId,
              accessKeySecret: res.accessKeySecret,
              stsToken: res.securityToken,
              bucket: res.bucketName,
            })
            const filename = originalName
            const response = {
              'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
            }
            const urlDowm = client.signatureUrl(objectName, { response })
            const a = document.createElement('a')
            // a.target = '_blank'
            a.href = urlDowm
            a.click()
          })
      })
    },
    getAuthorizationPdf(item) {
      const { originalName, objectName }  = item
      this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getAliyunOssSTS,
        }).then((res) => {
          const OSS = require('ali-oss')
          const client = new OSS({
            region: res.region,
            accessKeyId: res.accessKeyId,
            accessKeySecret: res.accessKeySecret,
            stsToken: res.securityToken,
            bucket: res.bucketName,
          })
          const filename = originalName
          const response = {
            'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
          }
          const urlDowm = client.signatureUrl(objectName, { response })
          const a = document.createElement('a')
          // a.target = '_blank'
          a.href = urlDowm
          a.click()
        })
    },
    beforeUpload() {
      this.pdfLoading = true
    },
    // 授权书上传
    authorUploadFileLoad({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileFrom', 'COMPANY_INFO')
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreFileupload,
        data: formData,
        // headers,
        showLoading: false,
      })
        .then((data) => {
          this.pdfLoading = false
          console.log(data)
          this.filePic = data
          this.originalName = data.originalName
          this.authorizationFile = data.fileHost + '/' + data.objectName
          this.ruleForm.authorizationFileId = data.id
        })
        .catch(() => {
          this.pdfLoading = false
        })
    },
     // 营业执照
    businessUploadFileLoad({ file }) {
      const size = +parseFloat(file.size / 1024 / 1024).toFixed(2)
      if (size >= 5) {
        this.$element.showMsg(this.$t('reg.authorUploadPic'), 'warning')
        return
      }
      console.log(size)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileFrom', 'COMPANY_INFO')
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreFileupload,
        data: formData,
        // headers,
        showLoading: false,
      })
        .then((data) => {
          console.log(data)
          this.businessLicenseFile = data.fileHost + '/' + data.objectName
          this.ruleForm.businessLicenseFileId = data.id
        })
        .catch(() => {
          this.loading = false
        })
    }

  }
}
