// 账号密钥分配
export default {
  title: '账号分配列表',
  allocateTime: '分配时间',
  assignAccount: '分配账号',
  keyType: '密钥类型',
  versionType: '产品版本',
  content: '分配内容',
  keyActivationCode: '密钥激活码',
  termOfValidity: '有效期',
  allocationStatus:'分配状态',
  activationStatus:'激活状态',
  validYears:'密钥有效期（年）',
  validMonths:'密钥有效期（月）',
  remoteGuidance:'远程指导',
  workflow:'工作流',
  Renewal:'续期',
  isBackOut:'撤回',
  productSecretKey: '产品密钥',
  funcSecretKey: '功能密钥',
  isActivation:'激活',
  workflowProductVersion:'工作流产品版本',
}
